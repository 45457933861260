import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ApiCallHelper, BaseComponent, CommonModalService } from "rabobank-utilities";
import { Observable } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";
import { ExtendedObjectApiCallHelper } from "src/app/services/ExtendedObjectApiCallHelper";
import { GovernanceWebApi, IProjectTeam } from "src/app/services/GovernanceApi";
import { ProjectTeamFormComponent } from "../../forms/project-team-form/component";
import { IDataProvider } from "../../forms/project-team-form/IDataProvider";

@Component({
    selector: "governance-project-team-edit-modal",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"],
})
export class ProjectTeamEditModalComponent extends BaseComponent {
    @Output("recordSaved") public recordSavedEventEmitter: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild(ProjectTeamFormComponent) public projectTeamFormComponent: ProjectTeamFormComponent;

    public dataProvider: IDataProvider = {
        getProjectTeam$: (id) => {
            return this.extendedObjectApiCallHelper.getProjectTeam$(id);
        },

        getTrackPaginatedResult$: (paginatedRequestParameters) => {
            return this.extendedObjectApiCallHelper.getTrackPaginatedResult$(paginatedRequestParameters);
        },

        getTrackFromValue$: (id) => {
            return this.extendedObjectApiCallHelper.getTrack$(id);
        },

        trackListUpdated$: this.extendedObjectApiCallHelper.extendedTrackListUpdated$,
    };

    public constructor(
        private extendedObjectApiCallHelper: ExtendedObjectApiCallHelper,
        private governanceWebApi: GovernanceWebApi,
        private apiCallHelper: ApiCallHelper,
        private commonModalService: CommonModalService,
        public bsModalRef: BsModalRef,
    ) {
        super();

        this.initialize();
    }

    private initialize(): void {

    }

    public initializeModal(id: number | null): void {
        if (id != null) {
            this.projectTeamFormComponent.loadRecord(id);
        }
    }

    public onFormSubmit(projectTeam: IProjectTeam): void {
        let loadingModalRef = this.commonModalService.showLoadingModal("Saving record...");

        let call: Observable<number>;

        if (projectTeam.id != null) {
            call = this.governanceWebApi.projectTeam.update$(projectTeam.id, projectTeam)
                .pipe(map(x => projectTeam.id));
        }
        else {
            call = this.governanceWebApi.projectTeam.create$(projectTeam);
        }

        this.apiCallHelper.call$(call)
            .pipe(this.apiCallHelper.callResultUnwrapPipe())
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: (id) => {
                    this.recordSavedEventEmitter.emit(id);

                    this.bsModalRef.hide();
                },
            })
            .add(() => loadingModalRef.hide());
    }
}
