import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { MsalBroadcastService } from "@azure/msal-angular";
import { InteractionStatus } from "@azure/msal-browser";
import { Store } from "@ngrx/store";
import { BsModalService } from "ngx-bootstrap/modal";
import { ApiCallHelper, CommonModalService } from "rabobank-utilities";
import { Observable, of } from "rxjs";
import { filter, map, mergeMap, take } from "rxjs/operators";
import { NewUserModalComponent } from "../components/home/modals/new-user-modal/component";
import { GovernanceWebApi } from "../services/GovernanceApi";
import { IAppState } from "../store";

@Injectable()
export class UserInProjectTeamRouteGuard implements CanActivate {
    public constructor(
        private router: Router,
        private governanceWebApi: GovernanceWebApi,
        private apiCallHelper: ApiCallHelper,
        private store: Store<IAppState>,
        private bsModalService: BsModalService,
        private commonModalService: CommonModalService,
        private msalBroadcastService: MsalBroadcastService,
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        //Try finding the user in the store. This is done so on subsequent route changes, the user doesn't have to be retrieved from the API every single time
        return this.store.select(x => x.userInformation.user)
            .pipe(mergeMap((user) => {
                //If the user isn't in the store yet, retrieve the user from the API
                if (user == null) {
                    return this.msalBroadcastService.inProgress$
                        .pipe(filter(status => status == InteractionStatus.None))
                        .pipe(mergeMap(() => {
                            return this.apiCallHelper.call$(this.governanceWebApi.user.getUser$())
                                .pipe(map((callResult) => {
                                    //If the call didn't succeed
                                    if (callResult.success == false) {
                                        //If apiExceptionDetails is null, something went wrong with the http call itself
                                        if (callResult.apiExceptionDetails == null) {
                                            this.commonModalService.showHttpErrorModal(callResult.httpErrorResponse!);
        
                                            throw new Error("An error occurred during an HTTP call. Error data: " + JSON.stringify(callResult.httpErrorResponse));
                                        }
        
                                        //If the returned error is that the user doesn't exist yet, return null
                                        if (callResult.apiExceptionDetails.errorMessage.indexOf("User does not exist yet") > -1) {
                                            return null;
                                        }
                                        else { //If the call didn't succeed for a different reason, show an error modal and throw
                                            this.commonModalService.showApiExceptionDetailsModal(callResult.apiExceptionDetails);
        
                                            throw "An error occurred while trying to load the user.";
                                        }
                                    }
        
                                    //If the call did succeed, return the user
                                    return callResult.result!;
                                }));
                        }));
                }
                else { //If the user is in the store, return the stored user
                    return of(user);
                }
            }))
            .pipe(take(1))
            .pipe(map((user) => {
                if (user == null) {
                    let bsModalRef = this.bsModalService.show(NewUserModalComponent, {
                        class: "col-12 col-md-8",
                        ignoreBackdropClick: true,
                    });

                    (<NewUserModalComponent>bsModalRef.content).initializeModal();

                    return false;
                }

                return true;
            }));
    }
}