import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { BaseComponent, IPaginatedFilterTableModalFieldTypeConfiguration } from "rabobank-utilities";
import { take, takeUntil } from "rxjs/operators";
import { IExtendedProjectTeam } from "src/app/models/IExtendedProjectTeam";
import { PaginatedFilterTableConfigurationService } from "src/app/services/PaginatedFilterTableConfigurationService";
import { IDataProvider } from "./IDataProvider";

@Component({
    selector: "governance-new-user-form",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"]
})
export class NewUserFormComponent extends BaseComponent {
    @Input() dataProvider: IDataProvider;

    @Output("formSubmit") public formSubmitEventEmitter: EventEmitter<number> = new EventEmitter<number>();

    public formGroup: FormGroup = new FormGroup({});
    public model = {} as {
        projectTeamId: number,
    };
    public formlyFormOptions: FormlyFormOptions = {};
    public formlyFieldConfigList: FormlyFieldConfig[];

    public constructor(
        private paginatedFilterTableConfigurationService: PaginatedFilterTableConfigurationService,
    ) {
        super();

        this.initialize();
    }

    private initialize(): void {
        this.onInit$
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.formlyFieldConfigList = [
                        {
                            key: ["projectTeamId"],
                            type: "paginated-filter-table-modal",
                            templateOptions: {
                                label: "Project team",
                                type: "number",
                                required: true,
                                paginatedFilterTableModalFieldTypeConfiguration: {
                                    modalTitle: "Select a project team",

                                    modalConfig: {
                                        class: "col-12 col-md-8",
                                        ignoreBackdropClick: true,
                                    },

                                    paginatedFilterTableConfiguration: this.paginatedFilterTableConfigurationService.getProjectTeamPaginatedFilterTableConfiguration(),

                                    dataProvider: {
                                        getPaginatedResult$: (paginatedRequestParameters) => this.dataProvider.getProjectTeamPaginatedResult$(paginatedRequestParameters),
                                        listUpdated$: this.dataProvider.projectTeamListUpdated$,
                                    },

                                    getItemFromValue$: (id) => this.dataProvider.getProjectTeamFromValue$(id),

                                    itemValueSelector: (item) => item?.id,

                                    itemLabelSelector: (item) => item?.name ?? "No project team selected",
                                } as IPaginatedFilterTableModalFieldTypeConfiguration<IExtendedProjectTeam, number>,
                            },
                        },
                    ];
                },
            });
    }

    public onFormSubmit(): void {
        if (this.formGroup.valid) {
            this.formSubmitEventEmitter.emit(this.model.projectTeamId);
        }
    }
}