import { Inject, Injectable } from "@angular/core";
import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions } from "@microsoft/signalr";
import { Subject } from "rxjs";
import { API_URL, HUB_HTTP_CONNECTION_OPTIONS } from "../InjectionTokens";

@Injectable()
export class GovernanceDataHub {
    private hubConnection: HubConnection;
    public onConnectionError$: Subject<any> = new Subject<any>();
    public onReconnecting$: Subject<Error> = new Subject<Error>();
    public onReconnected$: Subject<void> = new Subject<void>();
    public onConnectionClose$: Subject<Error> = new Subject<Error>();
    public claimableEnvironmentListUpdated$: Subject<void> = new Subject<void>();
    public claimableEnvironmentTypeListUpdated$: Subject<void> = new Subject<void>();
    public claimEntityListUpdated$: Subject<void> = new Subject<void>();
    public crossVistaTrackTemplateListUpdated$: Subject<void> = new Subject<void>();
    public environmentClaimListUpdated$: Subject<void> = new Subject<void>();
    public projectTeamListUpdated$: Subject<void> = new Subject<void>();
    public trackListUpdated$: Subject<void> = new Subject<void>();
    public userListUpdated$: Subject<void> = new Subject<void>();
    
    public constructor(
        private hubConnectionBuilder: HubConnectionBuilder,
        @Inject(API_URL) private apiUrl: string,
        @Inject(HUB_HTTP_CONNECTION_OPTIONS) private hubHttpConnectionOptions: IHttpConnectionOptions,
    ) {
        
    }

    public connect(): void {
        this.hubConnection = this.hubConnectionBuilder
            .withUrl(this.apiUrl + "/hubs/GovernanceDataHub", this.hubHttpConnectionOptions)
            .withAutomaticReconnect()
            .build();

        this.hubConnection.start()
            .then(
                () => { },
                (reason) => { this.onConnectionError$.next(reason); }
            );
        this.hubConnection.onreconnecting(error => { this.onReconnecting$.next(error); })
        this.hubConnection.onreconnected(() => { this.onReconnected$.next(); });
        this.hubConnection.onclose(error => { this.onConnectionClose$.next(error); });

        this.hubConnection.on("ClaimableEnvironmentListUpdated", (() => this.claimableEnvironmentListUpdated$.next()).bind(this));
        this.hubConnection.on("ClaimableEnvironmentTypeListUpdated", (() => this.claimableEnvironmentTypeListUpdated$.next()).bind(this));
        this.hubConnection.on("ClaimEntityListUpdated", (() => this.claimEntityListUpdated$.next()).bind(this));
        this.hubConnection.on("CrossVistaTrackTemplateListUpdated", (() => this.crossVistaTrackTemplateListUpdated$.next()).bind(this));
        this.hubConnection.on("EnvironmentClaimListUpdated", (() => this.environmentClaimListUpdated$.next()).bind(this));
        this.hubConnection.on("ProjectTeamListUpdated", (() => this.projectTeamListUpdated$.next()).bind(this));
        this.hubConnection.on("TrackListUpdated", (() => this.trackListUpdated$.next()).bind(this));
        this.hubConnection.on("UserListUpdated", (() => this.userListUpdated$.next()).bind(this));
    }
}
