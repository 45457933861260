import { Component, EventEmitter, Output } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ApiCallHelper, BaseComponent, CommonModalService } from "rabobank-utilities";
import { take, takeUntil } from "rxjs/operators";
import { ExtendedObjectApiCallHelper } from "src/app/services/ExtendedObjectApiCallHelper";
import { GovernanceWebApi } from "src/app/services/GovernanceApi";

@Component({
    selector: "governance-set-jenkins-job-successful-for-environment-modal",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"],
})
export class SetJenkinsJobSuccessfulForEnvironmentModalComponent extends BaseComponent {
    @Output("recordSaved") public recordSavedEventEmitter: EventEmitter<void> = new EventEmitter<void>();

    public constructor(
        private extendedObjectApiCallHelper: ExtendedObjectApiCallHelper,
        private governanceWebApi: GovernanceWebApi,
        private apiCallHelper: ApiCallHelper,
        private commonModalService: CommonModalService,
        public bsModalRef: BsModalRef,
    ) {
        super();

        this.initialize();
    }

    private initialize(): void {

    }

    public initializeModal(): void {

    }

    public onFormSubmit(environmentNumber: number): void {
        let loadingModalRef = this.commonModalService.showLoadingModal("Saving record...");

        this.apiCallHelper.call$(this.governanceWebApi.environmentClaim.setJenkinsJobSuccessfulForEnvironment$(environmentNumber))
            .pipe(this.apiCallHelper.callResultUnwrapPipe())
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.recordSavedEventEmitter.emit();

                    this.bsModalRef.hide();
                },
            })
            .add(() => loadingModalRef.hide());
    }
}
