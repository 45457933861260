<div class="modal-header">
    <h5 class="modal-title">Claimable environment type</h5>
    <fa-icon class="btn close"
             [icon]="['fas', 'times']"
             size="lg"
             (click)="bsModalRef.hide()"></fa-icon>
</div>
<div class="modal-body">
    <governance-claimable-environment-type-form [dataProvider]="dataProvider"
                                                (formSubmit)="onFormSubmit($event)"></governance-claimable-environment-type-form>
</div>