import { Component, Input } from "@angular/core";
import { BaseComponent } from "rabobank-utilities";
import { combineLatest, ReplaySubject } from "rxjs";
import { mergeMap, startWith, take, takeUntil } from "rxjs/operators";
import { IExtendedEnvironmentClaim } from "src/app/models/IExtendedEnvironmentClaim";
import { IDataProvider } from "./IDataProvider";

@Component({
    selector: "governance-azure-devops-work-item-information",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"],
})
export class AzureDevOpsWorkItemInformationComponent extends BaseComponent {
    @Input() public dataProvider: IDataProvider;
    @Input() public environmentClaimId: number;

    public environmentClaim$: ReplaySubject<IExtendedEnvironmentClaim> = new ReplaySubject<IExtendedEnvironmentClaim>(1);

    public constructor(

    ) {
        super();

        this.initialize();
    }

    private initialize(): void {
        this.onInit$
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    combineLatest([
                        this.dataProvider.environmentClaimListUpdated$.pipe(startWith([null])),
                    ])
                        .pipe(mergeMap(() => {
                            return this.dataProvider.getEnvironmentClaim$(this.environmentClaimId);
                        }))
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: (environmentClaim) => {
                                this.environmentClaim$.next(environmentClaim);
                            },
                        });
                },
            });
    }
}
