import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { ClaimEntityManageViewComponent } from "./components/administration/views/claim-entity-manage-view/component";
import { ClaimableEnvironmentManageViewComponent } from "./components/administration/views/claimable-environment-manage-view/component";
import { ClaimableEnvironmentTypeManageViewComponent } from "./components/administration/views/claimable-environment-type-manage-view/component";
import { CrossVistaTrackTemplateManageViewComponent } from "./components/administration/views/cross-vista-track-template-manage-view/component";
import { EnvironmentClaimManageViewComponent } from "./components/administration/views/environment-claim-manage-view/component";
import { ProjectTeamManageViewComponent } from "./components/administration/views/project-team-manage-view/component";
import { TrackManageViewComponent } from "./components/administration/views/track-manage-view/component";
import { UserManageViewComponent } from "./components/administration/views/user-manage-view/component";
import { CurrentEnvironmentClaimOverviewViewComponent } from "./components/home/views/current-environment-claim-overview-view/component";
import { UserInProjectTeamRouteGuard } from "./route-guards/UserInProjectTeamRouteGuard";

const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: "home/claim-overview",
    },

    {
        path: "home",
        canActivate: [MsalGuard, UserInProjectTeamRouteGuard],
        children: [
            {
                path: "claim-overview",
                component: CurrentEnvironmentClaimOverviewViewComponent,
            },

            {
                path: "claim-overview/:claimableEnvironmentTypeIdentifier",
                component: CurrentEnvironmentClaimOverviewViewComponent,
            }
        ],
    },

    {
        path: "administration",
        canActivate: [MsalGuard, UserInProjectTeamRouteGuard],
        children: [
            {
                path: "claimable-environment-manage",
                component: ClaimableEnvironmentManageViewComponent,
            },

            {
                path: "claimable-environment-type-manage",
                component: ClaimableEnvironmentTypeManageViewComponent,
            },

            {
                path: "claim-entity-manage",
                component: ClaimEntityManageViewComponent,
            },

            {
                path: "cross-vista-track-template-manage",
                component: CrossVistaTrackTemplateManageViewComponent,
            },

            {
                path: "environment-claim-manage",
                component: EnvironmentClaimManageViewComponent,
            },

            {
                path: "project-team-manage",
                component: ProjectTeamManageViewComponent,
            },

            {
                path: "track-manage",
                component: TrackManageViewComponent,
            },

            {
                path: "user-manage",
                component: UserManageViewComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
