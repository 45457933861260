import { Component } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { ApiCallHelper, BaseComponent, CommonModalService } from "rabobank-utilities";
import { take, takeUntil } from "rxjs/operators";
import { ExtendedObjectApiCallHelper } from "src/app/services/ExtendedObjectApiCallHelper";
import { GovernanceWebApi } from "src/app/services/GovernanceApi";
import { NavbarActions } from "src/app/store";
import { IDataProvider } from "../../manage/claimable-environment-type-manage/IDataProvider";
import { ClaimableEnvironmentTypeEditModalComponent } from "../../modals/claimable-environment-type-edit-modal/component";
import { SetDisableClaimingForAllClaimableEnvironmentTypesModalComponent } from "../../modals/set-disable-claiming-for-all-claimable-environment-types-modal/component";

@Component({
    selector: "governance-claimable-environment-type-manage-view",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"],
})
export class ClaimableEnvironmentTypeManageViewComponent extends BaseComponent {
    public dataProvider: IDataProvider = {
        getClaimableEnvironmentTypePaginatedResult$: (paginatedRequestParameters) => {
            return this.extendedObjectApiCallHelper.getClaimableEnvironmentTypePaginatedResult$(paginatedRequestParameters);
        },

        claimableEnvironmentTypeListUpdated$: this.extendedObjectApiCallHelper.extendedClaimableEnvironmentTypeListUpdated$,
    };

    public constructor(
        private extendedObjectApiCallHelper: ExtendedObjectApiCallHelper,
        private governanceWebApi: GovernanceWebApi,
        private apiCallHelper: ApiCallHelper,
        private commonModalService: CommonModalService,
        private navbarActions: NavbarActions,
        private bsModalService: BsModalService,

    ) {
        super();

        this.initialize();
    }

    private initialize(): void {
        this.onInit$
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.navbarActions.setNavbarTitle("Claimable environment types");

                    this.navbarActions.setNavbarMenuItemList([
                        {
                            label: "Actions",
                            id: "actions",
                            subMenuItemList: [
                                {
                                    label: "Enable/disable claiming for all types",
                                    id: "enable-disable-claiming-for-all-types",
                                    action: () => {
                                        let bsModalRef = this.bsModalService.show(SetDisableClaimingForAllClaimableEnvironmentTypesModalComponent, {
                                            class: "col-12 col-md-8",
                                            ignoreBackdropClick: true,
                                        });

                                        (<SetDisableClaimingForAllClaimableEnvironmentTypesModalComponent>bsModalRef.content).initializeModal();
                                    },
                                },
                            ],
                        },
                    ]);
                },
            });

        this.onDestroy$
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.navbarActions.setNavbarMenuItemList([]);
                },
            });
    }

    public onAddRecord(): void {
        let bsModalRef = this.bsModalService.show(ClaimableEnvironmentTypeEditModalComponent, {
            class: "col-12 col-md-8",
            ignoreBackdropClick: true,
        });

        (<ClaimableEnvironmentTypeEditModalComponent>bsModalRef.content).initializeModal(null);
    }

    public onEditRecord(id: number): void {
        let bsModalRef = this.bsModalService.show(ClaimableEnvironmentTypeEditModalComponent, {
            class: "col-12 col-md-8",
            ignoreBackdropClick: true,
        });

        (<ClaimableEnvironmentTypeEditModalComponent>bsModalRef.content).initializeModal(id);
    }

    public onDeleteRecord(id: number): void {
        this.commonModalService.showConfirmModal(
            "Are you sure you want to delete this record? Warning: This will also delete all child records depending on this record.",
            "",
            () => {
                let loadingModalRef = this.commonModalService.showLoadingModal("Deleting record...");

                return this.apiCallHelper.call$(this.governanceWebApi.claimableEnvironmentType.delete$(id))
                    .pipe(this.apiCallHelper.callResultUnwrapPipe())
                    .pipe(takeUntil(this.onDestroy$))
                    .pipe(take(1))
                    .subscribe({
                        next: () => {

                        },
                    })
                    .add(() => loadingModalRef.hide());
            }, () => {

            });
    }
}