import { Component, Input } from "@angular/core";
import { BaseComponent } from "rabobank-utilities";
import { combineLatest, ReplaySubject } from "rxjs";
import { mergeMap, startWith, take, takeUntil } from "rxjs/operators";
import { IExtendedEnvironmentClaim } from "src/app/models/IExtendedEnvironmentClaim";
import { IExtendedEnvironmentClaimJenkinsUpdate } from "src/app/models/IExtendedEnvironmentClaimJenkinsUpdate";
import { IDataProvider } from "./IDataProvider";
import { IGroupedEnvironmentClaimJenkinsUpdate } from "./IGroupedEnvironmentClaimJenkinsUpdate";

@Component({
    selector: "governance-jenkins-information",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"],
})
export class JenkinsInformationComponent extends BaseComponent {
    @Input() public dataProvider: IDataProvider;
    @Input() public environmentClaimId: number;

    public environmentClaim$: ReplaySubject<IExtendedEnvironmentClaim> = new ReplaySubject<IExtendedEnvironmentClaim>(1);
    public groupedEnvironmentClaimJenkinsUpdateList$: ReplaySubject<IGroupedEnvironmentClaimJenkinsUpdate[]> = new ReplaySubject<IGroupedEnvironmentClaimJenkinsUpdate[]>(1);

    public constructor(

    ) {
        super();

        this.initialize();
    }

    private initialize(): void {
        this.onInit$
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    combineLatest([
                        this.dataProvider.environmentClaimListUpdated$.pipe(startWith([null])),
                    ])
                        .pipe(mergeMap(() => {
                            return this.dataProvider.getEnvironmentClaim$(this.environmentClaimId);
                        }))
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: (environmentClaim) => {
                                //Order the resync list from most recent to oldest
                                environmentClaim.extendedEnvironmentClaimResyncList = environmentClaim.extendedEnvironmentClaimResyncList.sort((a, b) => a.resyncDate > b.resyncDate ? -1 : (a.resyncDate < b.resyncDate ? 1 : 0));

                                this.environmentClaim$.next(environmentClaim);
                                this.groupedEnvironmentClaimJenkinsUpdateList$.next(this.createGroupedEnvironmentClaimJenkinsUpdateList(environmentClaim.extendedEnvironmentClaimJenkinsUpdateList));
                            },
                        });
                },
            });
    }

    public createGroupedEnvironmentClaimJenkinsUpdateList(environmentClaimJenkinsUpdateList: IExtendedEnvironmentClaimJenkinsUpdate[]): IGroupedEnvironmentClaimJenkinsUpdate[] {
        let groupedEnvironmentClaimJenkinsUpdateList: IGroupedEnvironmentClaimJenkinsUpdate[] = [];

        for (let environmentClaimJenkinsUpdate of environmentClaimJenkinsUpdateList) {
            let dateObject = new Date(environmentClaimJenkinsUpdate.date);
            let groupDate = new Date(Date.UTC(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate(), 0, 0, 0, 0));

            let groupedEnvironmentClaimJenkinsUpdate = groupedEnvironmentClaimJenkinsUpdateList.find(x => x.date.toISOString() == groupDate.toISOString());

            if (groupedEnvironmentClaimJenkinsUpdate == null) {
                groupedEnvironmentClaimJenkinsUpdate = {
                    date: groupDate,
                    environmentClaimJenkinsUpdateList: [],
                };

                groupedEnvironmentClaimJenkinsUpdateList.push(groupedEnvironmentClaimJenkinsUpdate);
            }

            groupedEnvironmentClaimJenkinsUpdate.environmentClaimJenkinsUpdateList.push(environmentClaimJenkinsUpdate);
        }

        groupedEnvironmentClaimJenkinsUpdateList.sort((a, b) => {
            return a.date > b.date ? -1 : (a.date < b.date ? 1 : 0);
        });

        for (let groupedEnvironmentClaimJenkinsUpdate of groupedEnvironmentClaimJenkinsUpdateList) {
            groupedEnvironmentClaimJenkinsUpdate.environmentClaimJenkinsUpdateList.sort((a, b) => {
                return a.date > b.date ? -1 : (a.date < b.date ? 1 : 0);
            })
        }

        return groupedEnvironmentClaimJenkinsUpdateList;
    }
}
