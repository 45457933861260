import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ApiCallHelper, BaseComponent, CommonModalService } from "rabobank-utilities";
import { Observable } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";
import { ExtendedObjectApiCallHelper } from "src/app/services/ExtendedObjectApiCallHelper";
import { GovernanceWebApi, IEnvironmentClaim } from "src/app/services/GovernanceApi";
import { EnvironmentClaimFormComponent } from "../../forms/environment-claim-form/component";
import { IDataProvider } from "../../forms/environment-claim-form/IDataProvider";

@Component({
    selector: "governance-environment-claim-edit-modal",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"],
})
export class EnvironmentClaimEditModalComponent extends BaseComponent {
    @Output("recordSaved") public recordSavedEventEmitter: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild(EnvironmentClaimFormComponent) public environmentClaimFormComponent: EnvironmentClaimFormComponent;

    public dataProvider: IDataProvider = {
        getEnvironmentClaim$: (id) => {
            return this.extendedObjectApiCallHelper.getEnvironmentClaimWithoutAzureDevOpsWorkItem$(id);
        },

        getClaimableEnvironmentPaginatedResult$: (paginatedRequestParameters) => {
            return this.extendedObjectApiCallHelper.getClaimableEnvironmentPaginatedResult$(paginatedRequestParameters);
        },

        getClaimableEnvironmentFromValue$: (id) => {
            return this.extendedObjectApiCallHelper.getClaimableEnvironment$(id);
        },

        getClaimEntityPaginatedResult$: (paginatedRequestParameters) => {
            return this.extendedObjectApiCallHelper.getClaimEntityPaginatedResult$(paginatedRequestParameters);
        },

        getClaimEntityFromValue$: (id) => {
            return this.extendedObjectApiCallHelper.getClaimEntity$(id);
        },

        getCrossVistaTrackTemplatePaginatedResult$: (paginatedRequestParameters) => {
            return this.extendedObjectApiCallHelper.getCrossVistaTrackTemplatePaginatedResult$(paginatedRequestParameters);
        },

        getCrossVistaTrackTemplateFromValue$: (id) => {
            return this.extendedObjectApiCallHelper.getCrossVistaTrackTemplate$(id);
        },

        getUserPaginatedResult$: (paginatedRequestParameters) => {
            return this.extendedObjectApiCallHelper.getUserPaginatedResult$(paginatedRequestParameters);
        },

        getUserFromValue$: (id) => {
            return this.extendedObjectApiCallHelper.getUser$(id);
        },

        claimableEnvironmentListUpdated$: this.extendedObjectApiCallHelper.extendedClaimableEnvironmentListUpdated$,
        claimEntityListUpdated$: this.extendedObjectApiCallHelper.extendedClaimEntityListUpdated$,
        crossVistaTrackTemplateListUpdated$: this.extendedObjectApiCallHelper.extendedCrossVistaTrackTemplateListUpdated$,
        userListUpdated$: this.extendedObjectApiCallHelper.extendedUserListUpdated$,
    };

    public constructor(
        private extendedObjectApiCallHelper: ExtendedObjectApiCallHelper,
        private governanceWebApi: GovernanceWebApi,
        private apiCallHelper: ApiCallHelper,
        private commonModalService: CommonModalService,
        public bsModalRef: BsModalRef,
    ) {
        super();

        this.initialize();
    }

    private initialize(): void {

    }

    public initializeModal(id: number | null): void {
        if (id != null) {
            this.environmentClaimFormComponent.loadRecord(id);
        }
    }

    public onFormSubmit(environmentClaim: IEnvironmentClaim): void {
        let loadingModalRef = this.commonModalService.showLoadingModal("Saving record...");

        let call: Observable<number>;

        if (environmentClaim.id != null) {
            call = this.governanceWebApi.environmentClaim.update$(environmentClaim.id, environmentClaim)
                .pipe(map(x => environmentClaim.id));
        }
        else {
            call = this.governanceWebApi.environmentClaim.create$(environmentClaim);
        }

        this.apiCallHelper.call$(call)
            .pipe(this.apiCallHelper.callResultUnwrapPipe())
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: (id) => {
                    this.recordSavedEventEmitter.emit(id);

                    this.bsModalRef.hide();
                },
            })
            .add(() => loadingModalRef.hide());
    }
}
