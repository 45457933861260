import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ApiCallHelper, BaseComponent, CommonModalService } from "rabobank-utilities";
import { Observable } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";
import { ExtendedObjectApiCallHelper } from "src/app/services/ExtendedObjectApiCallHelper";
import { GovernanceWebApi, IClaimableEnvironment } from "src/app/services/GovernanceApi";
import { ClaimableEnvironmentFormComponent } from "../../forms/claimable-environment-form/component";
import { IDataProvider } from "../../forms/claimable-environment-form/IDataProvider";

@Component({
    selector: "governance-claimable-environment-edit-modal",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"],
})
export class ClaimableEnvironmentEditModalComponent extends BaseComponent {
    @Output("recordSaved") public recordSavedEventEmitter: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild(ClaimableEnvironmentFormComponent) public claimableEnvironmentFormComponent: ClaimableEnvironmentFormComponent;

    public dataProvider: IDataProvider = {
        getClaimableEnvironment$: (id) => {
            return this.extendedObjectApiCallHelper.getClaimableEnvironment$(id);
        },

        getClaimableEnvironmentTypePaginatedResult$: (paginatedRequestParameters) => {
            return this.extendedObjectApiCallHelper.getClaimableEnvironmentTypePaginatedResult$(paginatedRequestParameters);
        },

        getClaimableEnvironmentTypeFromValue$: (id) => {
            return this.extendedObjectApiCallHelper.getClaimableEnvironmentType$(id);
        },

        getProjectTeamPaginatedResult$: (paginatedRequestParameters) => {
            return this.extendedObjectApiCallHelper.getProjectTeamPaginatedResult$(paginatedRequestParameters);
        },

        getProjectTeamFromValue$: (id) => {
            return this.extendedObjectApiCallHelper.getProjectTeam$(id);
        },

        claimableEnvironmentTypeListUpdated$: this.extendedObjectApiCallHelper.extendedClaimableEnvironmentTypeListUpdated$,
        projectTeamListUpdated$: this.extendedObjectApiCallHelper.extendedProjectTeamListUpdated$,
    };

    public constructor(
        private extendedObjectApiCallHelper: ExtendedObjectApiCallHelper,
        private governanceWebApi: GovernanceWebApi,
        private apiCallHelper: ApiCallHelper,
        private commonModalService: CommonModalService,
        public bsModalRef: BsModalRef,
    ) {
        super();

        this.initialize();
    }

    private initialize(): void {
        
    }

    public initializeModal(id: number | null): void {
        if (id != null) {
            this.claimableEnvironmentFormComponent.loadRecord(id);
        }
    }

    public onFormSubmit(claimableEnvironment: IClaimableEnvironment): void {
        let loadingModalRef = this.commonModalService.showLoadingModal("Saving record...");

        let call: Observable<number>;

        if (claimableEnvironment.id != null) {
            call = this.governanceWebApi.claimableEnvironment.update$(claimableEnvironment.id, claimableEnvironment)
                .pipe(map(x => claimableEnvironment.id));
        }
        else {
            call = this.governanceWebApi.claimableEnvironment.create$(claimableEnvironment);
        }

        this.apiCallHelper.call$(call)
            .pipe(this.apiCallHelper.callResultUnwrapPipe())
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: (id) => {
                    this.recordSavedEventEmitter.emit(id);

                    this.bsModalRef.hide();
                },
            })
            .add(() => loadingModalRef.hide());
    }
}
