import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { BaseComponent } from "rabobank-utilities";
import { take, takeUntil } from "rxjs/operators";
import { IClaimableEnvironmentType } from "src/app/services/GovernanceApi";
import { IDataProvider } from "./IDataProvider";

@Component({
    selector: "governance-claimable-environment-type-form",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"]
})
export class ClaimableEnvironmentTypeFormComponent extends BaseComponent {
    @Input() dataProvider: IDataProvider;

    @Output("formSubmit") public formSubmitEventEmitter: EventEmitter<IClaimableEnvironmentType> = new EventEmitter<IClaimableEnvironmentType>();

    public formGroup: FormGroup = new FormGroup({});
    public model: IClaimableEnvironmentType = {
        requireBuildVersion: false,
        disableClaiming: false,
    } as IClaimableEnvironmentType;
    public formlyFormOptions: FormlyFormOptions = {};
    public formlyFieldConfigList: FormlyFieldConfig[];

    public constructor(

    ) {
        super();

        this.initialize();
    }

    private initialize(): void {
        this.onInit$
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.formlyFieldConfigList = [
                        {
                            key: ["id"],
                            type: "input",
                            templateOptions: {
                                label: "Id",
                                placeholder: "Id",
                                type: "number",
                                required: false,
                            },
                            hide: true,
                        },

                        {
                            key: ["identifier"],
                            type: "input",
                            templateOptions: {
                                label: "Identifier",
                                placeholder: "Identifier",
                                type: "text",
                                maxLength: 32,
                                required: true,
                            },
                        },

                        {
                            key: ["label"],
                            type: "input",
                            templateOptions: {
                                label: "Label",
                                placeholder: "Label",
                                type: "text",
                                maxLength: 32,
                                required: true,
                            },
                        },

                        {
                            key: ["name"],
                            type: "input",
                            templateOptions: {
                                label: "Name",
                                placeholder: "Name",
                                type: "text",
                                maxLength: 255,
                                required: true,
                            },
                        },

                        {
                            key: ["jenkinsJobName"],
                            type: "input",
                            templateOptions: {
                                label: "Jenkins job name",
                                placeholder: "Jenkins job name",
                                type: "text",
                                maxLength: 255,
                                required: true,
                            },
                        },

                        {
                            key: ["jenkinsJobUrl"],
                            type: "input",
                            templateOptions: {
                                label: "Jenkins job url",
                                placeholder: "Jenkins job url",
                                type: "text",
                                maxLength: 255,
                                required: true,
                            },
                        },

                        {
                            key: ["requireBuildVersion"],
                            type: "checkbox",
                            templateOptions: {
                                label: "Require build version",
                                required: true,
                            },
                        },

                        {
                            key: ["requireClaimEntity"],
                            type: "checkbox",
                            templateOptions: {
                                label: "Require claim entity",
                                required: true,
                            },
                        },

                        {
                            key: ["disableClaiming"],
                            type: "checkbox",
                            templateOptions: {
                                label: "Disable claiming",
                                required: true,
                            },
                        },

                        {
                            key: ["disableClaimingReason"],
                            type: "input",
                            templateOptions: {
                                label: "Disable claiming reason",
                                placeholder: "Disable claiming reason",
                                type: "text",
                                maxLength: 255,
                                required: true,
                            },
                            hideExpression: "model.disableClaiming == false",
                        },
                    ];
                },
            });
    }

    public loadRecord(id: number): void {
        this.dataProvider.getClaimableEnvironmentType$(id)
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: (claimableEnvironmentType) => {
                    this.model = claimableEnvironmentType;

                    this.formlyFormOptions.updateInitialValue();
                },
            });
    }

    public onFormSubmit(): void {
        if (this.formGroup.valid) {
            this.formSubmitEventEmitter.emit(this.model);
        }
    }
}