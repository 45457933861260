import { NgModule } from "@angular/core";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { GovernanceHub } from "./GovernanceHub";
import { GovernanceWebApi } from "./GovernanceWebApi";
import { GovernanceDataHub } from "./hubs/GovernanceDataHub";
import { AzureDevOpsWorkItemWebApi } from "./webapis/AzureDevOpsWorkItemWebApi";
import { ClaimableEnvironmentTypeWebApi } from "./webapis/ClaimableEnvironmentTypeWebApi";
import { ClaimableEnvironmentWebApi } from "./webapis/ClaimableEnvironmentWebApi";
import { ClaimEntityWebApi } from "./webapis/ClaimEntityWebApi";
import { CrossVistaBaselineWebApi } from "./webapis/CrossVistaBaselineWebApi";
import { CrossVistaTrackTemplateWebApi } from "./webapis/CrossVistaTrackTemplateWebApi";
import { EnvironmentClaimWebApi } from "./webapis/EnvironmentClaimWebApi";
import { ProjectTeamWebApi } from "./webapis/ProjectTeamWebApi";
import { TrackWebApi } from "./webapis/TrackWebApi";
import { UserWebApi } from "./webapis/UserWebApi";

@NgModule({
    providers: [
        HubConnectionBuilder,
        GovernanceHub,
        GovernanceDataHub,
        GovernanceWebApi,
        AzureDevOpsWorkItemWebApi,
        ClaimableEnvironmentTypeWebApi,
        ClaimableEnvironmentWebApi,
        CrossVistaBaselineWebApi,
        ClaimEntityWebApi,
        CrossVistaTrackTemplateWebApi,
        EnvironmentClaimWebApi,
        ProjectTeamWebApi,
        TrackWebApi,
        UserWebApi,
    ],
})
export class GovernanceApiModule { }