import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { BaseComponent, IPaginatedFilterTableModalFieldTypeConfiguration } from "rabobank-utilities";
import { take, takeUntil } from "rxjs/operators";
import { IExtendedClaimEntity } from "src/app/models/IExtendedClaimEntity";
import { IClaimEntity } from "src/app/services/GovernanceApi";
import { PaginatedFilterTableConfigurationService } from "src/app/services/PaginatedFilterTableConfigurationService";
import { IDataProvider } from "./IDataProvider";

@Component({
    selector: "governance-claim-entity-form",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"]
})
export class ClaimEntityFormComponent extends BaseComponent {
    @Input() dataProvider: IDataProvider;

    @Output("formSubmit") public formSubmitEventEmitter: EventEmitter<IClaimEntity> = new EventEmitter<IClaimEntity>();

    public formGroup: FormGroup = new FormGroup({});
    public model: IClaimEntity = {} as IClaimEntity;
    public formlyFormOptions: FormlyFormOptions = {};
    public formlyFieldConfigList: FormlyFieldConfig[];

    public constructor(
        private paginatedFilterTableConfigurationService: PaginatedFilterTableConfigurationService,
    ) {
        super();

        this.initialize();
    }

    private initialize(): void {
        this.onInit$
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.formlyFieldConfigList = [
                        {
                            key: ["id"],
                            type: "input",
                            templateOptions: {
                                label: "Id",
                                placeholder: "Id",
                                type: "number",
                                required: false,
                            },
                            hide: true,
                        },

                        {
                            key: ["name"],
                            type: "input",
                            templateOptions: {
                                label: "Name",
                                placeholder: "Name",
                                type: "text",
                                maxLength: 255,
                                required: true,
                            },
                        },

                        {
                            key: ["code"],
                            type: "input",
                            templateOptions: {
                                label: "Code",
                                placeholder: "Code",
                                type: "text",
                                maxLength: 255,
                                required: true,
                            },
                        },

                        {
                            key: ["requiredClaimEntityIdList"],
                            type: "repeat-section",
                            templateOptions: {
                                headerText: "Required claim entities",
                                addText: "Add another item",
                            },
                            fieldArray: {
                                key: ["claimEntityId"],
                                type: "paginated-filter-table-modal",
                                templateOptions: {
                                    label: "Claim entity",
                                    type: "number",
                                    required: true,
                                    paginatedFilterTableModalFieldTypeConfiguration: {
                                        modalTitle: "Select a claim entity",

                                        modalConfig: {
                                            class: "col-12 col-md-8",
                                            ignoreBackdropClick: true,
                                        },

                                        paginatedFilterTableConfiguration: this.paginatedFilterTableConfigurationService.getClaimEntityPaginatedFilterTableConfiguration(),

                                        dataProvider: {
                                            getPaginatedResult$: (paginatedRequestParameters) => this.dataProvider.getClaimEntityPaginatedResult$(paginatedRequestParameters),
                                            listUpdated$: this.dataProvider.claimEntityListUpdated$,
                                        },

                                        getItemFromValue$: (id) => this.dataProvider.getClaimEntityFromValue$(id),

                                        itemValueSelector: (item) => item?.id,

                                        itemLabelSelector: (item) => item?.name ?? "No claim entity selected",
                                    } as IPaginatedFilterTableModalFieldTypeConfiguration<IExtendedClaimEntity, number>,
                                },
                            },
                        },
                    ];
                },
            });
    }

    public loadRecord(id: number): void {
        this.dataProvider.getClaimEntity$(id)
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: (claimEntity) => {
                    this.model = claimEntity;

                    this.formlyFormOptions.updateInitialValue();
                },
            });
    }

    public onFormSubmit(): void {
        if (this.formGroup.valid) {
            this.formSubmitEventEmitter.emit(this.model);
        }
    }
}