import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { NavbarActions } from "./Navbar/NavbarActions";
import { metaReducers, reducers } from "./reducers";
import { UserInformationActions } from "./UserInformation/UserInformationActions";

@NgModule({
    providers: [
        NavbarActions,
        UserInformationActions,
    ],
    imports: [
        StoreModule.forRoot(reducers, { metaReducers, runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true } }),
    ],
})
export class GovernanceStoreModule { }
