import { Injectable } from "@angular/core";
import { ApiCallHelper, IPaginatedRequestParameters, IPaginatedResult } from "rabobank-utilities";
import { Observable, combineLatest, merge, of } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { IExtendedClaimEntity } from "../models/IExtendedClaimEntity";
import { IExtendedClaimableEnvironment } from "../models/IExtendedClaimableEnvironment";
import { IExtendedClaimableEnvironmentType } from "../models/IExtendedClaimableEnvironmentType";
import { IExtendedCrossVistaTrackTemplate } from "../models/IExtendedCrossVistaTrackTemplate";
import { IExtendedEnvironmentClaim } from "../models/IExtendedEnvironmentClaim";
import { IExtendedEnvironmentClaimJenkinsUpdate } from "../models/IExtendedEnvironmentClaimJenkinsUpdate";
import { IExtendedEnvironmentClaimResync } from "../models/IExtendedEnvironmentClaimResync";
import { IExtendedProjectTeam } from "../models/IExtendedProjectTeam";
import { IExtendedTrack } from "../models/IExtendedTrack";
import { IExtendedUser } from "../models/IExtendedUser";
import { GovernanceHub, GovernanceWebApi, IAzureDevOpsWorkItem, IClaimEntity, IClaimableEnvironment, IClaimableEnvironmentType, ICrossVistaTrackTemplate, IEnvironmentClaim, IEnvironmentClaimJenkinsUpdate, IEnvironmentClaimResync, IProjectTeam, ITrack, IUser } from "./GovernanceApi";

@Injectable()
export class ExtendedObjectApiCallHelper {
    public extendedClaimableEnvironmentListUpdated$: Observable<void> = merge(
        this.governanceHub.governanceData.claimableEnvironmentListUpdated$,
        this.governanceHub.governanceData.claimableEnvironmentTypeListUpdated$,
        this.governanceHub.governanceData.projectTeamListUpdated$,
        this.governanceHub.governanceData.trackListUpdated$,
    );

    public extendedClaimableEnvironmentTypeListUpdated$: Observable<void> = merge(
        this.governanceHub.governanceData.claimableEnvironmentTypeListUpdated$,
    );

    public extendedClaimEntityListUpdated$: Observable<void> = merge(
        this.governanceHub.governanceData.claimEntityListUpdated$,
    );

    public extendedCrossVistaTrackTemplateListUpdated$: Observable<void> = merge(
        this.governanceHub.governanceData.crossVistaTrackTemplateListUpdated$,
    );

    public extendedEnvironmentClaimListUpdated$: Observable<void> = merge(
        this.governanceHub.governanceData.environmentClaimListUpdated$,
        this.governanceHub.governanceData.claimableEnvironmentListUpdated$,
        this.governanceHub.governanceData.claimableEnvironmentTypeListUpdated$,
        this.governanceHub.governanceData.claimEntityListUpdated$,
        this.governanceHub.governanceData.crossVistaTrackTemplateListUpdated$,
        this.governanceHub.governanceData.projectTeamListUpdated$,
        this.governanceHub.governanceData.trackListUpdated$,
        this.governanceHub.governanceData.userListUpdated$,
    );

    public extendedProjectTeamListUpdated$: Observable<void> = merge(
        this.governanceHub.governanceData.projectTeamListUpdated$,
        this.governanceHub.governanceData.trackListUpdated$,
    );

    public extendedTrackListUpdated$: Observable<void> = merge(
        this.governanceHub.governanceData.trackListUpdated$,
    );

    public extendedUserListUpdated$: Observable<void> = merge(
        this.governanceHub.governanceData.userListUpdated$,
        this.governanceHub.governanceData.projectTeamListUpdated$,
        this.governanceHub.governanceData.trackListUpdated$,
    );

    public constructor(
        private governanceWebApi: GovernanceWebApi,
        private governanceHub: GovernanceHub,
        private apiCallHelper: ApiCallHelper,
    ) {

    }

    public getCurrentUser$(): Observable<IExtendedUser | null> {
        return this.apiCallHelper.call$(this.governanceWebApi.user.getUser$())
            .pipe(mergeMap((callResult) => {
                if (callResult.success == true) {
                    return this.getUser$(callResult.result.id);
                }
                else { //If the call fails, the user likely doesn't exist yet, so return null instead
                    return of(null);
                }
            }));
    }

    public getUser$(id: number): Observable<IExtendedUser> {
        return this.getUserPaginatedResult$({
            page: 1,
            itemsPerPage: 1,
            whereConditionList: [
                {
                    propertyName: "Id",
                    operator: "==",
                    value: id.toString(),
                },
            ],
            sortConditionList: [],
        })
            .pipe(map(paginatedResult => {
                if (paginatedResult.totalItems == 0) {
                    throw new Error("A record with id " + id + "could not be found.");
                }

                return paginatedResult.itemList[0];
            }));
    }

    public getUserPaginatedResult$(paginatedRequestParameters: IPaginatedRequestParameters): Observable<IPaginatedResult<IExtendedUser>> {
        return this.apiCallHelper.call$(this.governanceWebApi.user.getPaginatedResult$(paginatedRequestParameters))
            .pipe(this.apiCallHelper.callResultUnwrapPipe())
            .pipe(mergeMap((userPaginatedResult) => {
                let projectTeamIdList = userPaginatedResult.itemList.map(x => x.projectTeamId);

                return combineLatest([
                    of(userPaginatedResult),

                    projectTeamIdList.length == 0 ? of([] as IProjectTeam[]) : this.apiCallHelper.call$(this.governanceWebApi.projectTeam.getPaginatedResult$({
                        page: 1,
                        itemsPerPage: projectTeamIdList.length,
                        whereConditionList: [{
                            propertyName: "Id",
                            operator: "==",
                            value: projectTeamIdList.join("|"),
                        }],
                        sortConditionList: [],
                    }))
                        .pipe(this.apiCallHelper.callResultUnwrapPipe())
                        .pipe(map((paginatedResult) => paginatedResult.itemList)),
                ]);
            }))
            .pipe(mergeMap(([
                userPaginatedResult,
                projectTeamList,
            ]) => {
                let trackIdList = projectTeamList.map(x => x.trackId);

                return combineLatest([
                    of(userPaginatedResult),
                    of(projectTeamList),

                    trackIdList.length == 0 ? of([] as ITrack[]) : this.apiCallHelper.call$(this.governanceWebApi.track.getPaginatedResult$({
                        page: 1,
                        itemsPerPage: trackIdList.length,
                        whereConditionList: [{
                            propertyName: "Id",
                            operator: "==",
                            value: trackIdList.join("|"),
                        }],
                        sortConditionList: [],
                    }))
                        .pipe(this.apiCallHelper.callResultUnwrapPipe())
                        .pipe(map((paginatedResult) => paginatedResult.itemList)),
                ]);
            }))
            .pipe(map(([
                userPaginatedResult,
                projectTeamList,
                trackList,
            ]) => {
                return {
                    ...userPaginatedResult,

                    itemList: userPaginatedResult.itemList.map(user => this.createExtendedUser(user, projectTeamList, trackList)),
                };
            }));
    }

    private createExtendedUser(user: IUser, projectTeamList: IProjectTeam[], trackList: ITrack[]): IExtendedUser {
        let projectTeam = projectTeamList.find(x => x.id == user.projectTeamId);

        return {
            ...user,

            extendedProjectTeam: this.createExtendedProjectTeam(projectTeam, trackList),
        };
    }

    public getClaimableEnvironment$(id: number): Observable<IExtendedClaimableEnvironment> {
        return this.getClaimableEnvironmentPaginatedResult$({
            page: 1,
            itemsPerPage: 1,
            whereConditionList: [
                {
                    propertyName: "Id",
                    operator: "==",
                    value: id.toString(),
                },
            ],
            sortConditionList: [],
        })
            .pipe(map(paginatedResult => {
                if (paginatedResult.totalItems == 0) {
                    throw new Error("A record with id " + id + "could not be found.");
                }

                return paginatedResult.itemList[0];
            }));
    }

    public getClaimableEnvironmentPaginatedResult$(paginatedRequestParameters: IPaginatedRequestParameters): Observable<IPaginatedResult<IExtendedClaimableEnvironment>> {
        return this.apiCallHelper.call$(this.governanceWebApi.claimableEnvironment.getPaginatedResult$(paginatedRequestParameters))
            .pipe(this.apiCallHelper.callResultUnwrapPipe())
            .pipe(mergeMap((claimableEnvironmentPaginatedResult) => {
                let claimableEnvironmentTypeIdList = claimableEnvironmentPaginatedResult.itemList.map(x => x.claimableEnvironmentTypeId);
                let projectTeamIdList = claimableEnvironmentPaginatedResult.itemList.map(x => x.projectTeamId).filter(x => x != null);

                return combineLatest([
                    of(claimableEnvironmentPaginatedResult),

                    claimableEnvironmentTypeIdList.length == 0 ? of([] as IClaimableEnvironmentType[]) : this.apiCallHelper.call$(this.governanceWebApi.claimableEnvironmentType.getPaginatedResult$({
                        page: 1,
                        itemsPerPage: claimableEnvironmentTypeIdList.length,
                        whereConditionList: [{
                            propertyName: "Id",
                            operator: "==",
                            value: claimableEnvironmentTypeIdList.join("|"),
                        }],
                        sortConditionList: [],
                    }))
                        .pipe(this.apiCallHelper.callResultUnwrapPipe())
                        .pipe(map((paginatedResult) => paginatedResult.itemList)),

                    projectTeamIdList.length == 0 ? of([] as IProjectTeam[]) : this.apiCallHelper.call$(this.governanceWebApi.projectTeam.getPaginatedResult$({
                        page: 1,
                        itemsPerPage: projectTeamIdList.length,
                        whereConditionList: [{
                            propertyName: "Id",
                            operator: "==",
                            value: projectTeamIdList.join("|"),
                        }],
                        sortConditionList: [],
                    }))
                        .pipe(this.apiCallHelper.callResultUnwrapPipe())
                        .pipe(map((paginatedResult) => paginatedResult.itemList)),
                ]);
            }))
            .pipe(mergeMap(([
                claimableEnvironmentPaginatedResult,
                claimableEnvironmentTypeList,
                projectTeamList,
            ]) => {
                let trackIdList = projectTeamList.map(x => x.trackId);

                return combineLatest([
                    of(claimableEnvironmentPaginatedResult),
                    of(claimableEnvironmentTypeList),
                    of(projectTeamList),

                    trackIdList.length == 0 ? of([] as ITrack[]) : this.apiCallHelper.call$(this.governanceWebApi.track.getPaginatedResult$({
                        page: 1,
                        itemsPerPage: trackIdList.length,
                        whereConditionList: [{
                            propertyName: "Id",
                            operator: "==",
                            value: trackIdList.join("|"),
                        }],
                        sortConditionList: [],
                    }))
                        .pipe(this.apiCallHelper.callResultUnwrapPipe())
                        .pipe(map((paginatedResult) => paginatedResult.itemList)),
                ]);
            }))
            .pipe(map(([
                claimableEnvironmentPaginatedResult,
                claimableEnvironmentTypeList,
                projectTeamList,
                trackList,
            ]) => {
                return {
                    ...claimableEnvironmentPaginatedResult,

                    itemList: claimableEnvironmentPaginatedResult.itemList.map(claimableEnvironment => this.createExtendedClaimableEnvironment(claimableEnvironment, claimableEnvironmentTypeList, projectTeamList, trackList)),
                };
            }));
    }

    private createExtendedClaimableEnvironment(claimableEnvironment: IClaimableEnvironment, claimableEnvironmentTypeList: IClaimableEnvironmentType[], projectTeamList: IProjectTeam[], trackList: ITrack[]): IExtendedClaimableEnvironment {
        let claimableEnvironmentType = claimableEnvironmentTypeList.find(x => x.id == claimableEnvironment.claimableEnvironmentTypeId);
        let projectTeam = projectTeamList.find(x => x.id == claimableEnvironment.projectTeamId);

        return {
            ...claimableEnvironment,

            extendedClaimableEnvironmentType: this.createExtendedClaimableEnvironmentType(claimableEnvironmentType),
            extendedProjectTeam: claimableEnvironment.projectTeamId != null ? this.createExtendedProjectTeam(projectTeam, trackList) : null,
        };
    }

    public getClaimableEnvironmentType$(id: number): Observable<IExtendedClaimableEnvironmentType> {
        return this.getClaimableEnvironmentTypePaginatedResult$({
            page: 1,
            itemsPerPage: 1,
            whereConditionList: [
                {
                    propertyName: "Id",
                    operator: "==",
                    value: id.toString(),
                },
            ],
            sortConditionList: [],
        })
            .pipe(map(paginatedResult => {
                if (paginatedResult.totalItems == 0) {
                    throw new Error("A record with id " + id + "could not be found.");
                }

                return paginatedResult.itemList[0];
            }));
    }

    public getClaimableEnvironmentTypePaginatedResult$(paginatedRequestParameters: IPaginatedRequestParameters): Observable<IPaginatedResult<IExtendedClaimableEnvironmentType>> {
        return this.apiCallHelper.call$(this.governanceWebApi.claimableEnvironmentType.getPaginatedResult$(paginatedRequestParameters))
            .pipe(this.apiCallHelper.callResultUnwrapPipe())
            .pipe(map((claimableEnvironmentTypePaginatedResult) => {
                return {
                    ...claimableEnvironmentTypePaginatedResult,

                    itemList: claimableEnvironmentTypePaginatedResult.itemList.map(claimableEnvironmentType => this.createExtendedClaimableEnvironmentType(claimableEnvironmentType)),
                };
            }));
    }

    private createExtendedClaimableEnvironmentType(claimableEnvironmentType: IClaimableEnvironmentType): IExtendedClaimableEnvironmentType {
        return {
            ...claimableEnvironmentType,
        };
    }

    public getClaimEntity$(id: number): Observable<IExtendedClaimEntity> {
        return this.getClaimEntityPaginatedResult$({
            page: 1,
            itemsPerPage: 1,
            whereConditionList: [
                {
                    propertyName: "Id",
                    operator: "==",
                    value: id.toString(),
                },
            ],
            sortConditionList: [],
        })
            .pipe(map(paginatedResult => {
                if (paginatedResult.totalItems == 0) {
                    throw new Error("A record with id " + id + "could not be found.");
                }

                return paginatedResult.itemList[0];
            }));
    }

    public getClaimEntityPaginatedResult$(paginatedRequestParameters: IPaginatedRequestParameters): Observable<IPaginatedResult<IExtendedClaimEntity>> {
        return this.apiCallHelper.call$(this.governanceWebApi.claimEntity.getPaginatedResult$(paginatedRequestParameters))
            .pipe(this.apiCallHelper.callResultUnwrapPipe())
            .pipe(mergeMap((claimEntityPaginatedResult) => {
                let requiredClaimEntityIdList = ([] as number[]).concat.apply([], claimEntityPaginatedResult.itemList.map(x => x.requiredClaimEntityIdList));

                return combineLatest([
                    of(claimEntityPaginatedResult),

                    requiredClaimEntityIdList.length == 0 ? of([] as IClaimEntity[]) : this.apiCallHelper.call$(this.governanceWebApi.claimEntity.getPaginatedResult$({
                        page: 1,
                        itemsPerPage: requiredClaimEntityIdList.length,
                        whereConditionList: [{
                            propertyName: "Id",
                            operator: "==",
                            value: requiredClaimEntityIdList.join("|"),
                        }],
                        sortConditionList: [],
                    }))
                        .pipe(this.apiCallHelper.callResultUnwrapPipe())
                        .pipe(map((paginatedResult) => paginatedResult.itemList)),
                ]);
            }))
            .pipe(map(([
                claimEntityPaginatedResult,
                requiredClaimEntityList, ,
            ]) => {
                return {
                    ...claimEntityPaginatedResult,

                    itemList: claimEntityPaginatedResult.itemList.map(claimEntity => this.createExtendedClaimEntity(claimEntity, requiredClaimEntityList,)),
                };
            }));
    }

    private createExtendedClaimEntity(claimEntity: IClaimEntity, requiredClaimEntityList: IClaimEntity[] | null): IExtendedClaimEntity {
        return {
            ...claimEntity,

            extendedRequiredClaimEntityList: requiredClaimEntityList == null ? null : claimEntity.requiredClaimEntityIdList.map(requiredClaimEntityId => {
                return this.createExtendedClaimEntity(requiredClaimEntityList.find(x => x.id == requiredClaimEntityId), null);
            }),
        };
    }

    public getCrossVistaTrackTemplate$(id: number): Observable<IExtendedCrossVistaTrackTemplate> {
        return this.getCrossVistaTrackTemplatePaginatedResult$({
            page: 1,
            itemsPerPage: 1,
            whereConditionList: [
                {
                    propertyName: "Id",
                    operator: "==",
                    value: id.toString(),
                },
            ],
            sortConditionList: [],
        })
            .pipe(map(paginatedResult => {
                if (paginatedResult.totalItems == 0) {
                    throw new Error("A record with id " + id + "could not be found.");
                }

                return paginatedResult.itemList[0];
            }));
    }

    public getCrossVistaTrackTemplatePaginatedResult$(paginatedRequestParameters: IPaginatedRequestParameters): Observable<IPaginatedResult<IExtendedCrossVistaTrackTemplate>> {
        return this.apiCallHelper.call$(this.governanceWebApi.crossVistaTrackTemplate.getPaginatedResult$(paginatedRequestParameters))
            .pipe(this.apiCallHelper.callResultUnwrapPipe())
            .pipe(map((crossVistaTrackTemplatePaginatedResult) => {
                return {
                    ...crossVistaTrackTemplatePaginatedResult,

                    itemList: crossVistaTrackTemplatePaginatedResult.itemList.map(crossVistaTrackTemplate => this.createExtendedCrossVistaTrackTemplate(crossVistaTrackTemplate)),
                };
            }));
    }

    private createExtendedCrossVistaTrackTemplate(crossVistaTrackTemplate: ICrossVistaTrackTemplate): IExtendedCrossVistaTrackTemplate {
        return {
            ...crossVistaTrackTemplate,
        };
    }

    public getEnvironmentClaimWithoutAzureDevOpsWorkItem$(id: number): Observable<IExtendedEnvironmentClaim> {
        return this.getEnvironmentClaimWithoutAzureDevOpsWorkItemPaginatedResult$({
            page: 1,
            itemsPerPage: 1,
            whereConditionList: [
                {
                    propertyName: "Id",
                    operator: "==",
                    value: id.toString(),
                },
            ],
            sortConditionList: [],
        })
            .pipe(map(paginatedResult => {
                if (paginatedResult.totalItems == 0) {
                    throw new Error("A record with id " + id + "could not be found.");
                }

                return paginatedResult.itemList[0];
            }));
    }

    public getEnvironmentClaimWithAzureDevOpsWorkItem$(id: number): Observable<IExtendedEnvironmentClaim> {
        return this.getEnvironmentClaimWithAzureDevOpsWorkItemPaginatedResult$({
            page: 1,
            itemsPerPage: 1,
            whereConditionList: [
                {
                    propertyName: "Id",
                    operator: "==",
                    value: id.toString(),
                },
            ],
            sortConditionList: [],
        })
            .pipe(map(paginatedResult => {
                if (paginatedResult.totalItems == 0) {
                    throw new Error("A record with id " + id + "could not be found.");
                }

                return paginatedResult.itemList[0];
            }));
    }

    public getEnvironmentClaimWithoutAzureDevOpsWorkItemPaginatedResult$(paginatedRequestParameters: IPaginatedRequestParameters): Observable<IPaginatedResult<IExtendedEnvironmentClaim>> {
        return this.apiCallHelper.call$(this.governanceWebApi.environmentClaim.getPaginatedResult$(paginatedRequestParameters))
            .pipe(this.apiCallHelper.callResultUnwrapPipe())
            .pipe(mergeMap((environmentClaimPaginatedResult) => {
                let claimableEnvironmentIdList = environmentClaimPaginatedResult.itemList.map(x => x.claimableEnvironmentId);
                let userIdList = environmentClaimPaginatedResult.itemList.map(x => x.userId);
                let crossVistaTrackTemplateIdList = environmentClaimPaginatedResult.itemList.map(x => x.crossVistaTrackTemplateId).filter(x => x != null);
                let claimEntityIdList = ([] as number[]).concat.apply([], environmentClaimPaginatedResult.itemList.map(x => x.claimEntityIdList).filter(x => x != null));

                return combineLatest([
                    of(environmentClaimPaginatedResult),
                    claimableEnvironmentIdList.length == 0 ? of([] as IClaimableEnvironment[]) : this.apiCallHelper.call$(this.governanceWebApi.claimableEnvironment.getPaginatedResult$({
                        page: 1,
                        itemsPerPage: claimableEnvironmentIdList.length,
                        whereConditionList: [{
                            propertyName: "Id",
                            operator: "==",
                            value: claimableEnvironmentIdList.join("|"),
                        }],
                        sortConditionList: [],
                    }))
                        .pipe(this.apiCallHelper.callResultUnwrapPipe())
                        .pipe(map((paginatedResult) => paginatedResult.itemList)),

                    userIdList.length == 0 ? of([] as IUser[]) : this.apiCallHelper.call$(this.governanceWebApi.user.getPaginatedResult$({
                        page: 1,
                        itemsPerPage: userIdList.length,
                        whereConditionList: [{
                            propertyName: "Id",
                            operator: "==",
                            value: userIdList.join("|"),
                        }],
                        sortConditionList: [],
                    }))
                        .pipe(this.apiCallHelper.callResultUnwrapPipe())
                        .pipe(map((paginatedResult) => paginatedResult.itemList)),

                    crossVistaTrackTemplateIdList.length == 0 ? of([] as ICrossVistaTrackTemplate[]) : this.apiCallHelper.call$(this.governanceWebApi.crossVistaTrackTemplate.getPaginatedResult$({
                        page: 1,
                        itemsPerPage: crossVistaTrackTemplateIdList.length,
                        whereConditionList: [{
                            propertyName: "Id",
                            operator: "==",
                            value: crossVistaTrackTemplateIdList.join("|"),
                        }],
                        sortConditionList: [],
                    }))
                        .pipe(this.apiCallHelper.callResultUnwrapPipe())
                        .pipe(map((paginatedResult) => paginatedResult.itemList)),

                    claimEntityIdList.length == 0 ? of([] as IClaimEntity[]) : this.apiCallHelper.call$(this.governanceWebApi.claimEntity.getPaginatedResult$({
                        page: 1,
                        itemsPerPage: claimEntityIdList.length,
                        whereConditionList: [{
                            propertyName: "Id",
                            operator: "==",
                            value: claimEntityIdList.join("|"),
                        }],
                        sortConditionList: [],
                    }))
                        .pipe(this.apiCallHelper.callResultUnwrapPipe())
                        .pipe(map((paginatedResult) => paginatedResult.itemList)),
                ]);
            }))
            .pipe(mergeMap(([
                environmentClaimPaginatedResult,
                claimableEnvironmentList,
                userList,
                crossVistaTrackTemplateList,
                claimEntityList,
            ]) => {
                let requiredClaimEntityIdList = ([] as number[]).concat.apply([], claimEntityList.map(x => x.requiredClaimEntityIdList).filter(x => x != null));
                let claimableEnvironmentTypeIdList = claimableEnvironmentList.map(x => x.claimableEnvironmentTypeId);
                let projectTeamIdList = userList.map(x => x.projectTeamId).concat(claimableEnvironmentList.map(x => x.projectTeamId).filter(x => x != null));

                return combineLatest(
                    combineLatest([
                        of(environmentClaimPaginatedResult),
                        of(claimableEnvironmentList),
                        of(userList),
                        of(crossVistaTrackTemplateList),

                        //If there are no required claim entities, just return the already retrieved claim entity list. Otherwise, combine it with the required claim entity list
                        requiredClaimEntityIdList.length == 0 ? of(claimEntityList) : this.apiCallHelper.call$(this.governanceWebApi.claimEntity.getPaginatedResult$({
                            page: 1,
                            itemsPerPage: requiredClaimEntityIdList.length,
                            whereConditionList: [{
                                propertyName: "Id",
                                operator: "==",
                                value: requiredClaimEntityIdList.join("|"),
                            }],
                            sortConditionList: [],
                        }))
                            .pipe(this.apiCallHelper.callResultUnwrapPipe())
                            .pipe(map((paginatedResult) => paginatedResult.itemList))
                            .pipe(map((requiredClaimEntityList) => claimEntityList.concat(requiredClaimEntityList))),
                    ]),
                    combineLatest([
                        claimableEnvironmentTypeIdList.length == 0 ? of([] as IClaimableEnvironmentType[]) : this.apiCallHelper.call$(this.governanceWebApi.claimableEnvironmentType.getPaginatedResult$({
                            page: 1,
                            itemsPerPage: claimableEnvironmentTypeIdList.length,
                            whereConditionList: [{
                                propertyName: "Id",
                                operator: "==",
                                value: claimableEnvironmentTypeIdList.join("|"),
                            }],
                            sortConditionList: [],
                        }))
                            .pipe(this.apiCallHelper.callResultUnwrapPipe())
                            .pipe(map((paginatedResult) => paginatedResult.itemList)),

                        projectTeamIdList.length == 0 ? of([] as IProjectTeam[]) : this.apiCallHelper.call$(this.governanceWebApi.projectTeam.getPaginatedResult$({
                            page: 1,
                            itemsPerPage: projectTeamIdList.length,
                            whereConditionList: [{
                                propertyName: "Id",
                                operator: "==",
                                value: projectTeamIdList.join("|"),
                            }],
                            sortConditionList: [],
                        }))
                            .pipe(this.apiCallHelper.callResultUnwrapPipe())
                            .pipe(map((paginatedResult) => paginatedResult.itemList)),
                    ]),
                );
            }))
            .pipe(mergeMap(([
                [
                    environmentClaimPaginatedResult,
                    claimableEnvironmentList,
                    userList,
                    crossVistaTrackTemplateList,
                    claimEntityList,
                ],
                [
                    claimableEnvironmentTypeList,
                    projectTeamList,
                ],
            ]) => {
                let trackIdList = projectTeamList.map(x => x.trackId);

                return combineLatest([
                    combineLatest([
                        of(environmentClaimPaginatedResult),
                        of(claimableEnvironmentList),
                        of(userList),
                        of(crossVistaTrackTemplateList),
                        of(claimEntityList),
                        of(claimableEnvironmentTypeList),
                    ]),
                    combineLatest([
                        of(projectTeamList),

                        trackIdList.length == 0 ? of([] as ITrack[]) : this.apiCallHelper.call$(this.governanceWebApi.track.getPaginatedResult$({
                            page: 1,
                            itemsPerPage: trackIdList.length,
                            whereConditionList: [{
                                propertyName: "Id",
                                operator: "==",
                                value: trackIdList.join("|"),
                            }],
                            sortConditionList: [],
                        }))
                            .pipe(this.apiCallHelper.callResultUnwrapPipe())
                            .pipe(map((paginatedResult) => paginatedResult.itemList)),
                    ]),
                ]);
            }))
            .pipe(map(([
                [
                    environmentClaimPaginatedResult,
                    claimableEnvironmentList,
                    userList,
                    crossVistaTrackTemplateList,
                    claimEntityList,
                    claimableEnvironmentTypeList,
                ],
                [
                    projectTeamList,
                    trackList,
                ]
            ]) => {
                return {
                    ...environmentClaimPaginatedResult,

                    itemList: environmentClaimPaginatedResult.itemList.map(environmentClaim => this.createExtendedEnvironmentClaim(environmentClaim, claimableEnvironmentList, userList, crossVistaTrackTemplateList, claimEntityList, claimableEnvironmentTypeList, projectTeamList, trackList)),
                };
            }));
    }

    public getEnvironmentClaimWithAzureDevOpsWorkItemPaginatedResult$(paginatedRequestParameters: IPaginatedRequestParameters): Observable<IPaginatedResult<IExtendedEnvironmentClaim>> {
        return this.getEnvironmentClaimWithoutAzureDevOpsWorkItemPaginatedResult$(paginatedRequestParameters)
            .pipe(mergeMap((extendedEnvironmentClaimPaginatedResult) => {
                let azureDevOpsWorkItemIdList = extendedEnvironmentClaimPaginatedResult.itemList.map(x => x.azureDevOpsWorkItemId);

                return combineLatest([
                    of(extendedEnvironmentClaimPaginatedResult),

                    azureDevOpsWorkItemIdList.length == 0 ? of([] as IAzureDevOpsWorkItem[]) : this.apiCallHelper.call$(this.governanceWebApi.azureDevOpsWorkItem.getList$(azureDevOpsWorkItemIdList))
                        .pipe(this.apiCallHelper.callResultUnwrapPipe()),
                ])
            }))
            .pipe(map(([
                extendedEnvironmentClaimPaginatedResult,
                azureDevOpsWorkItemList,
            ]) => {
                //Loop through all the items and set their corresponding Azure DevOps work item
                for (let item of extendedEnvironmentClaimPaginatedResult.itemList) {
                    item.azureDevOpsWorkItem = azureDevOpsWorkItemList.find(x => x.id == item.azureDevOpsWorkItemId);
                }

                return extendedEnvironmentClaimPaginatedResult;
            }));
    }

    private createExtendedEnvironmentClaim(environmentClaim: IEnvironmentClaim, claimableEnvironmentList: IClaimableEnvironment[], userList: IUser[], crossVistaTrackTemplateList: ICrossVistaTrackTemplate[], claimEntityList: IClaimEntity[], claimableEnvironmentTypeList: IClaimableEnvironmentType[], projectTeamList: IProjectTeam[], trackList: ITrack[]): IExtendedEnvironmentClaim {
        let claimableEnvironment = claimableEnvironmentList.find(x => x.id == environmentClaim.claimableEnvironmentId);
        let user = userList.find(x => x.id == environmentClaim.userId);
        let crossVistaTrackTemplate = environmentClaim.crossVistaTrackTemplateId != null ? crossVistaTrackTemplateList.find(x => x.id == environmentClaim.crossVistaTrackTemplateId) : null;

        return {
            ...environmentClaim,

            azureDevOpsWorkItem: null, //Assign null by default. The getEnvironmentClaimWithAzureDevOpsWorkItemPaginatedResult$ method fills this value with the actual work item when it is required
            extendedClaimableEnvironment: this.createExtendedClaimableEnvironment(claimableEnvironment, claimableEnvironmentTypeList, projectTeamList, trackList),
            extendedUser: this.createExtendedUser(user, projectTeamList, trackList),
            extendedCrossVistaTrackTemplate: crossVistaTrackTemplate != null ? this.createExtendedCrossVistaTrackTemplate(crossVistaTrackTemplate) : null,

            extendedClaimEntityList: environmentClaim.claimEntityIdList.map(claimEntityId => this.createExtendedClaimEntity(claimEntityList.find(x => x.id == claimEntityId), claimEntityList)),
            extendedEnvironmentClaimJenkinsUpdateList: environmentClaim.environmentClaimJenkinsUpdateList.map(x => this.createExtendedEnvironmentClaimJenkinsUpdate(x)),
            extendedEnvironmentClaimResyncList: environmentClaim.environmentClaimResyncList.map(x => this.createExtendedEnvironmentClaimResync(x)),
        };
    }

    private createExtendedEnvironmentClaimJenkinsUpdate(environmentClaimJenkinsUpdate: IEnvironmentClaimJenkinsUpdate): IExtendedEnvironmentClaimJenkinsUpdate {
        return {
            ...environmentClaimJenkinsUpdate,
        };
    }

    private createExtendedEnvironmentClaimResync(environmentClaimResync: IEnvironmentClaimResync): IExtendedEnvironmentClaimResync {
        return {
            ...environmentClaimResync,
        };
    }

    public getProjectTeam$(id: number): Observable<IExtendedProjectTeam> {
        return this.getProjectTeamPaginatedResult$({
            page: 1,
            itemsPerPage: 1,
            whereConditionList: [
                {
                    propertyName: "Id",
                    operator: "==",
                    value: id.toString(),
                },
            ],
            sortConditionList: [],
        })
            .pipe(map(paginatedResult => {
                if (paginatedResult.totalItems == 0) {
                    throw new Error("A record with id " + id + "could not be found.");
                }

                return paginatedResult.itemList[0];
            }));
    }

    public getProjectTeamPaginatedResult$(paginatedRequestParameters: IPaginatedRequestParameters): Observable<IPaginatedResult<IExtendedProjectTeam>> {
        return this.apiCallHelper.call$(this.governanceWebApi.projectTeam.getPaginatedResult$(paginatedRequestParameters))
            .pipe(this.apiCallHelper.callResultUnwrapPipe())
            .pipe(mergeMap((projectTeamPaginatedResult) => {
                let trackIdList = projectTeamPaginatedResult.itemList.map(x => x.trackId);

                return combineLatest([
                    of(projectTeamPaginatedResult),

                    trackIdList.length == 0 ? of([] as ITrack[]) : this.apiCallHelper.call$(this.governanceWebApi.track.getPaginatedResult$({
                        page: 1,
                        itemsPerPage: trackIdList.length,
                        whereConditionList: [{
                            propertyName: "Id",
                            operator: "==",
                            value: trackIdList.join("|"),
                        }],
                        sortConditionList: [],
                    }))
                        .pipe(this.apiCallHelper.callResultUnwrapPipe())
                        .pipe(map((paginatedResult) => paginatedResult.itemList)),
                ]);
            }))
            .pipe(map(([
                projectTeamPaginatedResult,
                trackList,
            ]) => {
                return {
                    ...projectTeamPaginatedResult,

                    itemList: projectTeamPaginatedResult.itemList.map(projectTeam => this.createExtendedProjectTeam(projectTeam, trackList)),
                };
            }));
    }

    private createExtendedProjectTeam(projectTeam: IProjectTeam, trackList: ITrack[]): IExtendedProjectTeam {
        let track = trackList.find(x => x.id == projectTeam.trackId);

        return {
            ...projectTeam,

            extendedTrack: this.createExtendedTrack(track),
        };
    }

    public getTrack$(id: number): Observable<IExtendedTrack> {
        return this.getTrackPaginatedResult$({
            page: 1,
            itemsPerPage: 1,
            whereConditionList: [
                {
                    propertyName: "Id",
                    operator: "==",
                    value: id.toString(),
                },
            ],
            sortConditionList: [],
        })
            .pipe(map(paginatedResult => {
                if (paginatedResult.totalItems == 0) {
                    throw new Error("A record with id " + id + "could not be found.");
                }

                return paginatedResult.itemList[0];
            }));
    }

    public getTrackPaginatedResult$(paginatedRequestParameters: IPaginatedRequestParameters): Observable<IPaginatedResult<IExtendedTrack>> {
        return this.apiCallHelper.call$(this.governanceWebApi.track.getPaginatedResult$(paginatedRequestParameters))
            .pipe(this.apiCallHelper.callResultUnwrapPipe())
            .pipe(map((trackPaginatedResult) => {
                return {
                    ...trackPaginatedResult,

                    itemList: trackPaginatedResult.itemList.map(track => this.createExtendedTrack(track)),
                };
            }));
    }

    private createExtendedTrack(track: ITrack): IExtendedTrack {
        return {
            ...track,
        };
    }
}