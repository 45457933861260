<div class="card page-card">
    <div class="card-body">
        <governance-current-environment-claim-overview *ngIf="(claimableEnvironmentType$ | async) != null"
                                                       [claimableEnvironmentType$]="claimableEnvironmentType$"
                                                       [dataProvider]="dataProvider"
                                                       (claimEnvironment)="onClaimEnvironment($event)"
                                                       (unclaimEnvironment)="onUnclaimEnvironment($event)"
                                                       (resyncEnvironment)="onResyncEnvironment($event)"
                                                       (showJenkinsInformation)="onShowJenkinsInformation($event)"
                                                       (showAzureDevOpsWorkItemInformation)="onShowAzureDevOpsWorkItemInformation($event)"
                                                       (updateBuildVersion)="onUpdateBuildVersion($event)"></governance-current-environment-claim-overview>
    </div>
</div>