import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ApiCallHelper, BaseComponent, CommonModalService } from "rabobank-utilities";
import { Observable } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";
import { ExtendedObjectApiCallHelper } from "src/app/services/ExtendedObjectApiCallHelper";
import { GovernanceWebApi, IClaimableEnvironmentType } from "src/app/services/GovernanceApi";
import { ClaimableEnvironmentTypeFormComponent } from "../../forms/claimable-environment-type-form/component";
import { IDataProvider } from "../../forms/claimable-environment-type-form/IDataProvider";

@Component({
    selector: "governance-claimable-environment-type-edit-modal",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"],
})
export class ClaimableEnvironmentTypeEditModalComponent extends BaseComponent {
    @Output("recordSaved") public recordSavedEventEmitter: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild(ClaimableEnvironmentTypeFormComponent) public claimableEnvironmentTypeFormComponent: ClaimableEnvironmentTypeFormComponent;

    public dataProvider: IDataProvider = {
        getClaimableEnvironmentType$: (id) => {
            return this.extendedObjectApiCallHelper.getClaimableEnvironmentType$(id);
        },
    };

    public constructor(
        private extendedObjectApiCallHelper: ExtendedObjectApiCallHelper,
        private governanceWebApi: GovernanceWebApi,
        private apiCallHelper: ApiCallHelper,
        private commonModalService: CommonModalService,
        public bsModalRef: BsModalRef,
    ) {
        super();

        this.initialize();
    }

    private initialize(): void {
        
    }

    public initializeModal(id: number | null): void {
        if (id != null) {
            this.claimableEnvironmentTypeFormComponent.loadRecord(id);
        }
    }

    public onFormSubmit(claimableEnvironmentType: IClaimableEnvironmentType): void {
        let loadingModalRef = this.commonModalService.showLoadingModal("Saving record...");

        let call: Observable<number>;

        if (claimableEnvironmentType.id != null) {
            call = this.governanceWebApi.claimableEnvironmentType.update$(claimableEnvironmentType.id, claimableEnvironmentType)
                .pipe(map(x => claimableEnvironmentType.id));
        }
        else {
            call = this.governanceWebApi.claimableEnvironmentType.create$(claimableEnvironmentType);
        }

        this.apiCallHelper.call$(call)
            .pipe(this.apiCallHelper.callResultUnwrapPipe())
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: (id) => {
                    this.recordSavedEventEmitter.emit(id);

                    this.bsModalRef.hide();
                },
            })
            .add(() => loadingModalRef.hide());
    }
}
