import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { ApiCallHelper, BaseComponent, CommonModalService, LocalPaginatedRequestHandler } from "rabobank-utilities";
import { combineLatest, of, ReplaySubject } from "rxjs";
import { map, mergeMap, startWith, take, takeUntil } from "rxjs/operators";
import { IExtendedClaimableEnvironmentType } from "src/app/models/IExtendedClaimableEnvironmentType";
import { ExtendedObjectApiCallHelper } from "src/app/services/ExtendedObjectApiCallHelper";
import { GovernanceWebApi } from "src/app/services/GovernanceApi";
import { NavbarActions } from "src/app/store";
import { ICurrentEnvironment } from "../../manage/current-environment-claim-overview/ICurrentEnvironment";
import { IDataProvider } from "../../manage/current-environment-claim-overview/IDataProvider";
import { AzureDevOpsWorkItemInformationModalComponent } from "../../modals/azure-devops-work-item-information-modal/component";
import { HelpModalComponent } from "../../modals/help-modal/component";
import { JenkinsInformationModalComponent } from "../../modals/jenkins-information-modal/component";
import { StartEnvironmentClaimModalComponent } from "../../modals/start-environment-claim-modal/component";
import { UpdateBuildVersionModalComponent } from "../../modals/update-build-version-modal/component";

@Component({
    selector: "governance-current-environment-claim-overview-view",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"],
})
export class CurrentEnvironmentClaimOverviewViewComponent extends BaseComponent {
    public dataProvider: IDataProvider = {
        getCurrentEnvironmentList$: (claimableEnvironmentTypeIdentifier) => {
            return combineLatest([
                this.extendedObjectApiCallHelper.getEnvironmentClaimWithAzureDevOpsWorkItemPaginatedResult$({
                    page: 1,
                    itemsPerPage: 10000,
                    whereConditionList: [
                        {
                            propertyName: "ClaimableEnvironment.ClaimableEnvironmentType.Identifier",
                            operator: "==",
                            value: claimableEnvironmentTypeIdentifier,
                        },
                        {
                            propertyName: "ClaimDateEnd",
                            operator: "==",
                            value: null,
                        },
                    ],
                    sortConditionList: [],
                })
                    .pipe(map(paginatedResult => paginatedResult.itemList)),

                this.extendedObjectApiCallHelper.getClaimableEnvironmentPaginatedResult$({
                    page: 1,
                    itemsPerPage: 10000,
                    whereConditionList: [{
                        propertyName: "ClaimableEnvironmentType.Identifier",
                        operator: "==",
                        value: claimableEnvironmentTypeIdentifier,
                    }],
                    sortConditionList: [],
                })
                    .pipe(map(paginatedResult => paginatedResult.itemList)),
            ])
                .pipe(map(([
                    environmentClaimList,
                    claimableEnvironmentList,
                ]) => {
                    let currentEnvironmentList: ICurrentEnvironment[] = [];

                    //Loop through all claimable environments
                    for (let claimableEnvironment of claimableEnvironmentList) {
                        //Try finding an active environment claim for the claimable environment
                        let environmentClaim = environmentClaimList.find(x => x.claimableEnvironmentId == claimableEnvironment.id);

                        currentEnvironmentList.push({
                            extendedClaimableEnvironment: claimableEnvironment,
                            extendedEnvironmentClaim: environmentClaim != null ? environmentClaim : null,
                        });
                    }

                    return currentEnvironmentList;
                }));
        },

        getPaginatedResult$: (currentEnvironmentList$, paginatedRequestParameters) => {
            return this.localPaginatedRequestHandler.getPaginatedResult$(currentEnvironmentList$, paginatedRequestParameters);
        },

        getCurrentUser$: () => {
            return this.extendedObjectApiCallHelper.getCurrentUser$();
        },

        environmentClaimListUpdated$: this.extendedObjectApiCallHelper.extendedEnvironmentClaimListUpdated$,
        claimableEnvironmentListUpdated$: this.extendedObjectApiCallHelper.extendedClaimableEnvironmentListUpdated$,
        userListUpdated$: this.extendedObjectApiCallHelper.extendedUserListUpdated$,
    };

    public claimableEnvironmentType$: ReplaySubject<IExtendedClaimableEnvironmentType> = new ReplaySubject<IExtendedClaimableEnvironmentType>(1);

    public constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private extendedObjectApiCallHelper: ExtendedObjectApiCallHelper,
        private governanceWebApi: GovernanceWebApi,
        private apiCallHelper: ApiCallHelper,
        private commonModalService: CommonModalService,
        private navbarActions: NavbarActions,
        private bsModalService: BsModalService,
        private localPaginatedRequestHandler: LocalPaginatedRequestHandler,
    ) {
        super();

        this.initialize();
    }

    private initialize(): void {
        this.onInit$
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    combineLatest([
                        this.activatedRoute.paramMap,
                        this.extendedObjectApiCallHelper.extendedClaimableEnvironmentTypeListUpdated$.pipe(startWith([null])),
                    ])
                        .pipe(map(([
                            paramMap,
                        ]) => {
                            return paramMap.get("claimableEnvironmentTypeIdentifier");
                        }))
                        .pipe(mergeMap((claimableEnvironmentTypeIdentifier) => {
                            return combineLatest([
                                of(claimableEnvironmentTypeIdentifier),
                                this.extendedObjectApiCallHelper.getClaimableEnvironmentTypePaginatedResult$({
                                    page: 1,
                                    itemsPerPage: 10000,
                                    whereConditionList: [],
                                    sortConditionList: [],
                                }),
                            ]);
                        }))
                        .pipe(mergeMap(([
                            claimableEnvironmentTypeIdentifier,
                            paginatedResult,
                        ]) => {
                            return combineLatest([
                                of(claimableEnvironmentTypeIdentifier),
                                of(paginatedResult.itemList),
                            ]);
                        }))
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: ([
                                claimableEnvironmentTypeIdentifier,
                                claimableEnvironmentTypeList,
                            ]) => {
                                //If the page was accessed without a claimable environment type identifier, redirect to the first available one
                                if (claimableEnvironmentTypeIdentifier == null) {
                                    this.router.navigate(["/home", "claim-overview", claimableEnvironmentTypeList[0].identifier]);

                                    return;
                                }

                                let claimableEnvironmentType = claimableEnvironmentTypeList.find(x => x.identifier.toUpperCase() == claimableEnvironmentTypeIdentifier.toUpperCase());

                                if (claimableEnvironmentType == null) {
                                    this.commonModalService.showErrorModal("Claimable environment type \"" + claimableEnvironmentTypeIdentifier + "\" is invalid.");

                                    return; //TODO: Navigate away maybe?
                                }

                                this.claimableEnvironmentType$.next(claimableEnvironmentType);
                            },
                        });

                    this.claimableEnvironmentType$
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: (claimableEnvironmentType) => {
                                this.navbarActions.setNavbarTitle("Environment claims - " + claimableEnvironmentType.name);
                            },
                        });

                    this.navbarActions.setNavbarMenuItemList([
                        {
                            label: "Help",
                            id: "help",
                            action: () => {
                                let bsModalRef = this.bsModalService.show(HelpModalComponent, {
                                    class: "col-12 col-md-8",
                                    ignoreBackdropClick: true,
                                });

                                (<HelpModalComponent>bsModalRef.content).initializeModal();
                            },
                        },
                    ]);
                },
            });

        this.onDestroy$
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.navbarActions.setNavbarMenuItemList([]);
                },
            })
    }

    public onClaimEnvironment(currentEnvironment: ICurrentEnvironment): void {
        let bsModalRef = this.bsModalService.show(StartEnvironmentClaimModalComponent, {
            class: "col-12 col-md-8",
            ignoreBackdropClick: true,
        });

        (<StartEnvironmentClaimModalComponent>bsModalRef.content).initializeModal(currentEnvironment.extendedClaimableEnvironment);
    }

    public onUnclaimEnvironment(currentEnvironment: ICurrentEnvironment): void {
        this.commonModalService.showConfirmModal(
            "Are you sure you want to unclaim environment " + currentEnvironment.extendedClaimableEnvironment.number + "?",
            "Unclaim environment",
            () => {
                let loadingModalRef = this.commonModalService.showLoadingModal("Starting unclaim job...");

                this.apiCallHelper.call$(this.governanceWebApi.environmentClaim.startEnvironmentUnclaim$(currentEnvironment.extendedEnvironmentClaim.id))
                    .pipe(this.apiCallHelper.callResultUnwrapPipe())
                    .pipe(takeUntil(this.onDestroy$))
                    .pipe(take(1))
                    .subscribe({
                        next: () => {

                        },
                    })
                    .add(() => loadingModalRef.hide());
            },
            () => { }
        );
    }

    public onResyncEnvironment(currentEnvironment: ICurrentEnvironment): void {
        this.commonModalService.showConfirmModal(
            "Are you sure you want to resync environment " + currentEnvironment.extendedClaimableEnvironment.number + "?",
            "Resync environment",
            () => {
                let loadingModalRef = this.commonModalService.showLoadingModal("Starting resync job...");

                this.apiCallHelper.call$(this.governanceWebApi.environmentClaim.startEnvironmentResync$(currentEnvironment.extendedEnvironmentClaim.id))
                    .pipe(this.apiCallHelper.callResultUnwrapPipe())
                    .pipe(takeUntil(this.onDestroy$))
                    .pipe(take(1))
                    .subscribe({
                        next: () => {

                        },
                    })
                    .add(() => loadingModalRef.hide());
            },
            () => { }
        );
    }

    public onShowJenkinsInformation(currentEnvironment: ICurrentEnvironment): void {
        let bsModalRef = this.bsModalService.show(JenkinsInformationModalComponent, {
            class: "col-12 col-md-8",
            ignoreBackdropClick: true,
        });

        (<JenkinsInformationModalComponent>bsModalRef.content).initializeModal(currentEnvironment);
    }

    public onShowAzureDevOpsWorkItemInformation(currentEnvironment: ICurrentEnvironment): void {
        let bsModalRef = this.bsModalService.show(AzureDevOpsWorkItemInformationModalComponent, {
            class: "col-12 col-md-8",
            ignoreBackdropClick: true,
        });

        (<AzureDevOpsWorkItemInformationModalComponent>bsModalRef.content).initializeModal(currentEnvironment);
    }

    public onUpdateBuildVersion(currentEnvironment: ICurrentEnvironment): void {
        let bsModalRef = this.bsModalService.show(UpdateBuildVersionModalComponent, {
            class: "col-12 col-md-8",
            ignoreBackdropClick: true,
        });

        (<UpdateBuildVersionModalComponent>bsModalRef.content).initializeModal(currentEnvironment.extendedEnvironmentClaim.id);
    }

}