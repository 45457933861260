import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ApiCallHelper, BaseComponent, CommonModalService } from "rabobank-utilities";
import { take, takeUntil } from "rxjs/operators";
import { ExtendedObjectApiCallHelper } from "src/app/services/ExtendedObjectApiCallHelper";
import { GovernanceWebApi } from "src/app/services/GovernanceApi";
import { NewUserFormComponent } from "../../forms/new-user-form/component";
import { IDataProvider } from "../../forms/new-user-form/IDataProvider";

@Component({
    selector: "governance-update-build-version-modal",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"],
})
export class NewUserModalComponent extends BaseComponent {
    @Output("recordSaved") public recordSavedEventEmitter: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(NewUserFormComponent) public newUserFormComponent: NewUserFormComponent;

    public dataProvider: IDataProvider = {
        getProjectTeamPaginatedResult$: (paginatedRequestParameters) => {
            return this.extendedObjectApiCallHelper.getProjectTeamPaginatedResult$(paginatedRequestParameters);
        },

        getProjectTeamFromValue$: (id) => {
            return this.extendedObjectApiCallHelper.getProjectTeam$(id);
        },

        projectTeamListUpdated$: this.extendedObjectApiCallHelper.extendedProjectTeamListUpdated$,
    };

    public constructor(
        private extendedObjectApiCallHelper: ExtendedObjectApiCallHelper,
        private governanceWebApi: GovernanceWebApi,
        private apiCallHelper: ApiCallHelper,
        private commonModalService: CommonModalService,
        private router: Router,
        public bsModalRef: BsModalRef,
    ) {
        super();

        this.initialize();
    }

    private initialize(): void {
        
    }

    public initializeModal(): void {

    }

    public onFormSubmit(projectTeamId: number): void {
        let loadingModalRef = this.commonModalService.showLoadingModal("Saving record...");

        this.apiCallHelper.call$(this.governanceWebApi.user.createCurrentUser$(projectTeamId))
            .pipe(this.apiCallHelper.callResultUnwrapPipe())
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.recordSavedEventEmitter.emit();

                    window.location.reload();
                },
            })
            .add(() => loadingModalRef.hide());
    }
}
