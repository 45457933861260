import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FormlyModule } from "@ngx-formly/core";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { RabobankUtilitiesModule } from "rabobank-utilities";
import { ClaimEntityFormComponent } from "./components/administration/forms/claim-entity-form/component";
import { ClaimableEnvironmentFormComponent } from "./components/administration/forms/claimable-environment-form/component";
import { ClaimableEnvironmentTypeFormComponent } from "./components/administration/forms/claimable-environment-type-form/component";
import { CrossVistaTrackTemplateFormComponent } from "./components/administration/forms/cross-vista-track-template-form/component";
import { EnvironmentClaimFormComponent } from "./components/administration/forms/environment-claim-form/component";
import { ProjectTeamFormComponent } from "./components/administration/forms/project-team-form/component";
import { SetDisableClaimingForAllClaimableEnvironmentTypesFormComponent } from "./components/administration/forms/set-disable-claiming-for-all-claimable-environment-types-form/component";
import { SetJenkinsJobSuccessfulForEnvironmentFormComponent } from "./components/administration/forms/set-jenkins-job-successful-for-environment-form/component";
import { TrackFormComponent } from "./components/administration/forms/track-form/component";
import { UserFormComponent } from "./components/administration/forms/user-form/component";
import { ClaimEntityManageComponent } from "./components/administration/manage/claim-entity-manage/component";
import { ClaimableEnvironmentManageComponent } from "./components/administration/manage/claimable-environment-manage/component";
import { ClaimableEnvironmentTypeManageComponent } from "./components/administration/manage/claimable-environment-type-manage/component";
import { CrossVistaTrackTemplateManageComponent } from "./components/administration/manage/cross-vista-track-template-manage/component";
import { EnvironmentClaimManageComponent } from "./components/administration/manage/environment-claim-manage/component";
import { ProjectTeamManageComponent } from "./components/administration/manage/project-team-manage/component";
import { TrackManageComponent } from "./components/administration/manage/track-manage/component";
import { UserManageComponent } from "./components/administration/manage/user-manage/component";
import { ClaimEntityEditModalComponent } from "./components/administration/modals/claim-entity-edit-modal/component";
import { ClaimableEnvironmentEditModalComponent } from "./components/administration/modals/claimable-environment-edit-modal/component";
import { ClaimableEnvironmentTypeEditModalComponent } from "./components/administration/modals/claimable-environment-type-edit-modal/component";
import { CrossVistaTrackTemplateEditModalComponent } from "./components/administration/modals/cross-vista-track-template-edit-modal/component";
import { EnvironmentClaimEditModalComponent } from "./components/administration/modals/environment-claim-edit-modal/component";
import { ProjectTeamEditModalComponent } from "./components/administration/modals/project-team-edit-modal/component";
import { SetDisableClaimingForAllClaimableEnvironmentTypesModalComponent } from "./components/administration/modals/set-disable-claiming-for-all-claimable-environment-types-modal/component";
import { SetJenkinsJobSuccessfulForEnvironmentModalComponent } from "./components/administration/modals/set-jenkins-job-successful-for-environment-modal/component";
import { TrackEditModalComponent } from "./components/administration/modals/track-edit-modal/component";
import { UserEditModalComponent } from "./components/administration/modals/user-edit-modal/component";
import { ClaimEntityManageViewComponent } from "./components/administration/views/claim-entity-manage-view/component";
import { ClaimableEnvironmentManageViewComponent } from "./components/administration/views/claimable-environment-manage-view/component";
import { ClaimableEnvironmentTypeManageViewComponent } from "./components/administration/views/claimable-environment-type-manage-view/component";
import { CrossVistaTrackTemplateManageViewComponent } from "./components/administration/views/cross-vista-track-template-manage-view/component";
import { EnvironmentClaimManageViewComponent } from "./components/administration/views/environment-claim-manage-view/component";
import { ProjectTeamManageViewComponent } from "./components/administration/views/project-team-manage-view/component";
import { TrackManageViewComponent } from "./components/administration/views/track-manage-view/component";
import { UserManageViewComponent } from "./components/administration/views/user-manage-view/component";
import { NewUserFormComponent } from "./components/home/forms/new-user-form/component";
import { StartEnvironmentClaimFormComponent } from "./components/home/forms/start-environment-claim-form/component";
import { UpdateBuildVersionFormComponent } from "./components/home/forms/update-build-version-form/component";
import { AzureDevOpsWorkItemInformationComponent } from "./components/home/manage/azure-devops-work-item-information/component";
import { CurrentEnvironmentClaimOverviewComponent } from "./components/home/manage/current-environment-claim-overview/component";
import { JenkinsInformationComponent } from "./components/home/manage/jenkins-information/component";
import { AzureDevOpsWorkItemInformationModalComponent } from "./components/home/modals/azure-devops-work-item-information-modal/component";
import { HelpModalComponent } from "./components/home/modals/help-modal/component";
import { JenkinsInformationModalComponent } from "./components/home/modals/jenkins-information-modal/component";
import { NewUserModalComponent } from "./components/home/modals/new-user-modal/component";
import { StartEnvironmentClaimModalComponent } from "./components/home/modals/start-environment-claim-modal/component";
import { UpdateBuildVersionModalComponent } from "./components/home/modals/update-build-version-modal/component";
import { CurrentEnvironmentClaimOverviewViewComponent } from "./components/home/views/current-environment-claim-overview-view/component";

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        FontAwesomeModule,
        TooltipModule.forRoot(),
        RabobankUtilitiesModule,
        PaginationModule.forRoot(),
        BsDropdownModule.forRoot(),
        RouterModule,
        FormlyModule.forChild(),
    ],
    declarations: [
        NewUserModalComponent,
        NewUserFormComponent,

        HelpModalComponent,

        CurrentEnvironmentClaimOverviewViewComponent,
        CurrentEnvironmentClaimOverviewComponent,

        AzureDevOpsWorkItemInformationModalComponent,
        AzureDevOpsWorkItemInformationComponent,
        
        JenkinsInformationModalComponent,
        JenkinsInformationComponent,
        
        StartEnvironmentClaimModalComponent,
        StartEnvironmentClaimFormComponent,

        UpdateBuildVersionModalComponent,
        UpdateBuildVersionFormComponent,

        ClaimableEnvironmentManageViewComponent,
        ClaimableEnvironmentManageComponent,
        ClaimableEnvironmentEditModalComponent,
        ClaimableEnvironmentFormComponent,

        ClaimableEnvironmentTypeManageViewComponent,
        ClaimableEnvironmentTypeManageComponent,
        ClaimableEnvironmentTypeEditModalComponent,
        ClaimableEnvironmentTypeFormComponent,
        SetDisableClaimingForAllClaimableEnvironmentTypesModalComponent,
        SetDisableClaimingForAllClaimableEnvironmentTypesFormComponent,

        ClaimEntityManageViewComponent,
        ClaimEntityManageComponent,
        ClaimEntityEditModalComponent,
        ClaimEntityFormComponent,

        CrossVistaTrackTemplateManageViewComponent,
        CrossVistaTrackTemplateManageComponent,
        CrossVistaTrackTemplateEditModalComponent,
        CrossVistaTrackTemplateFormComponent,

        EnvironmentClaimManageViewComponent,
        EnvironmentClaimManageComponent,
        EnvironmentClaimEditModalComponent,
        EnvironmentClaimFormComponent,
        SetJenkinsJobSuccessfulForEnvironmentModalComponent,
        SetJenkinsJobSuccessfulForEnvironmentFormComponent,

        ProjectTeamManageViewComponent,
        ProjectTeamManageComponent,
        ProjectTeamEditModalComponent,
        ProjectTeamFormComponent,

        TrackManageViewComponent,
        TrackManageComponent,
        TrackEditModalComponent,
        TrackFormComponent,

        UserManageViewComponent,
        UserManageComponent,
        UserEditModalComponent,
        UserFormComponent,
    ],
    entryComponents: [
        NewUserModalComponent,
        HelpModalComponent,
        AzureDevOpsWorkItemInformationModalComponent,
        JenkinsInformationModalComponent,
        StartEnvironmentClaimModalComponent,
        UpdateBuildVersionModalComponent,
        
        ClaimableEnvironmentEditModalComponent,
        ClaimableEnvironmentTypeEditModalComponent,
        SetDisableClaimingForAllClaimableEnvironmentTypesModalComponent,
        EnvironmentClaimEditModalComponent,
        ProjectTeamEditModalComponent,
        TrackEditModalComponent,
        UserEditModalComponent,
    ],
    exports: [],
})
export class AppViewsModule {
    public constructor(library: FaIconLibrary) {
        library.addIconPacks(fas, far)
    }
}
