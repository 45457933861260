import { Component, EventEmitter, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { BaseComponent } from "rabobank-utilities";
import { take, takeUntil } from "rxjs/operators";

@Component({
    selector: "governance-set-jenkins-job-successful-for-environment-form",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"]
})
export class SetJenkinsJobSuccessfulForEnvironmentFormComponent extends BaseComponent {
    @Output("formSubmit") public formSubmitEventEmitter: EventEmitter<number> = new EventEmitter<number>();

    public formGroup: FormGroup = new FormGroup({});
    public model = {
        environmentNumber: 0,
    } as { environmentNumber: number };
    public formlyFormOptions: FormlyFormOptions = {};
    public formlyFieldConfigList: FormlyFieldConfig[];

    public constructor(

    ) {
        super();

        this.initialize();
    }

    private initialize(): void {
        this.onInit$
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.formlyFieldConfigList = [
                        {
                            key: ["environmentNumber"],
                            type: "input",
                            templateOptions: {
                                label: "Environment number",
                                placeholder: "Environment number",
                                type: "number",
                                min: 0,
                                required: true,
                            },
                        },
                    ];
                },
            });
    }

    public onFormSubmit(): void {
        if (this.formGroup.valid) {
            this.formSubmitEventEmitter.emit(this.model.environmentNumber);
        }
    }
}