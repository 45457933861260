import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ApiCallHelper, BaseComponent, CommonModalService } from "rabobank-utilities";
import { Observable } from "rxjs";
import { map, take, takeUntil } from "rxjs/operators";
import { ExtendedObjectApiCallHelper } from "src/app/services/ExtendedObjectApiCallHelper";
import { GovernanceWebApi, IUser } from "src/app/services/GovernanceApi";
import { UserFormComponent } from "../../forms/user-form/component";
import { IDataProvider } from "../../forms/user-form/IDataProvider";

@Component({
    selector: "governance-user-edit-modal",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"],
})
export class UserEditModalComponent extends BaseComponent {
    @Output("recordSaved") public recordSavedEventEmitter: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild(UserFormComponent) public userFormComponent: UserFormComponent;

    public dataProvider: IDataProvider = {
        getUser$: (id) => {
            return this.extendedObjectApiCallHelper.getUser$(id);
        },

        getProjectTeamPaginatedResult$: (paginatedRequestParameters) => {
            return this.extendedObjectApiCallHelper.getProjectTeamPaginatedResult$(paginatedRequestParameters);
        },

        getProjectTeamFromValue$: (id) => {
            return this.extendedObjectApiCallHelper.getProjectTeam$(id);
        },

        projectTeamListUpdated$: this.extendedObjectApiCallHelper.extendedProjectTeamListUpdated$,
    };

    public constructor(
        private extendedObjectApiCallHelper: ExtendedObjectApiCallHelper,
        private governanceWebApi: GovernanceWebApi,
        private apiCallHelper: ApiCallHelper,
        private commonModalService: CommonModalService,
        public bsModalRef: BsModalRef,
    ) {
        super();

        this.initialize();
    }

    private initialize(): void {

    }

    public initializeModal(id: number): void {
        if (id != null) {
            this.userFormComponent.loadRecord(id);
        }
    }

    public onFormSubmit(user: IUser): void {
        let loadingModalRef = this.commonModalService.showLoadingModal("Saving record...");

        let call: Observable<number>;

        if (user.id != null) {
            call = this.governanceWebApi.user.update$(user.id, user)
                .pipe(map(x => user.id));
        }
        else {
            call = this.governanceWebApi.user.create$(user);
        }

        this.apiCallHelper.call$(call)
            .pipe(this.apiCallHelper.callResultUnwrapPipe())
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: (id) => {
                    this.recordSavedEventEmitter.emit(id);

                    this.bsModalRef.hide();
                },
            })
            .add(() => loadingModalRef.hide());
    }
}
