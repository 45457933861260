import { Component } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { ApiCallHelper, BaseComponent, CommonModalService } from "rabobank-utilities";
import { take, takeUntil } from "rxjs/operators";
import { ExtendedObjectApiCallHelper } from "src/app/services/ExtendedObjectApiCallHelper";
import { GovernanceWebApi } from "src/app/services/GovernanceApi";
import { NavbarActions } from "src/app/store";
import { IDataProvider } from "../../manage/claimable-environment-manage/IDataProvider";
import { ClaimableEnvironmentEditModalComponent } from "../../modals/claimable-environment-edit-modal/component";

@Component({
    selector: "governance-claimable-environment-manage-view",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"],
})
export class ClaimableEnvironmentManageViewComponent extends BaseComponent {
    public dataProvider: IDataProvider = {
        getClaimableEnvironmentPaginatedResult$: (paginatedRequestParameters) => {
            return this.extendedObjectApiCallHelper.getClaimableEnvironmentPaginatedResult$(paginatedRequestParameters);
        },

        claimableEnvironmentListUpdated$: this.extendedObjectApiCallHelper.extendedClaimableEnvironmentListUpdated$,
    };

    public constructor(
        private extendedObjectApiCallHelper: ExtendedObjectApiCallHelper,
        private governanceWebApi: GovernanceWebApi,
        private apiCallHelper: ApiCallHelper,
        private commonModalService: CommonModalService,
        private navbarActions: NavbarActions,
        private bsModalService: BsModalService,

    ) {
        super();

        this.initialize();
    }

    private initialize(): void {
        this.onInit$
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.navbarActions.setNavbarTitle("Claimable environments");
                },
            });
    }

    public onAddRecord(): void {
        let bsModalRef = this.bsModalService.show(ClaimableEnvironmentEditModalComponent, {
            class: "col-12 col-md-8",
            ignoreBackdropClick: true,
        });

        (<ClaimableEnvironmentEditModalComponent>bsModalRef.content).initializeModal(null);
    }

    public onEditRecord(id: number): void {
        let bsModalRef = this.bsModalService.show(ClaimableEnvironmentEditModalComponent, {
            class: "col-12 col-md-8",
            ignoreBackdropClick: true,
        });

        (<ClaimableEnvironmentEditModalComponent>bsModalRef.content).initializeModal(id);
    }

    public onDeleteRecord(id: number): void {
        this.commonModalService.showConfirmModal(
            "Are you sure you want to delete this record? Warning: This will also delete all child records depending on this record.",
            "",
            () => {
                let loadingModalRef = this.commonModalService.showLoadingModal("Deleting record...");

                return this.apiCallHelper.call$(this.governanceWebApi.claimableEnvironment.delete$(id))
                    .pipe(this.apiCallHelper.callResultUnwrapPipe())
                    .pipe(takeUntil(this.onDestroy$))
                    .pipe(take(1))
                    .subscribe({
                        next: () => {

                        },
                    })
                    .add(() => loadingModalRef.hide());
            }, () => {

            });
    }
}