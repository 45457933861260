import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from "@azure/msal-angular";
import { InteractionStatus } from "@azure/msal-browser";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { HubConnectionBuilder, IHttpConnectionOptions } from "@microsoft/signalr";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { FormlyModule } from "@ngx-formly/core";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { ModalModule } from "ngx-bootstrap/modal";
import { RabobankLayoutModule } from "rabobank-layout";
import { AppConfigService, HttpClientLoadingInterceptor, MsalGuardConfigFactory, MsalInstanceFactory, MsalInterceptorConfigFactory, RabobankUtilitiesModule } from "rabobank-utilities";
import { filter, map, mergeMap, take } from "rxjs/operators";
import { AppRootComponent } from "src/app/components/app-root/component";
import { AppRoutingModule } from "./app-routing.module";
import { AppViewsModule } from "./app-views.module";
import { IEnvironmentConfiguration } from "./models/IEnvironmentConfiguration";
import { UserInProjectTeamRouteGuard } from "./route-guards/UserInProjectTeamRouteGuard";
import { ExtendedObjectApiCallHelper } from "./services/ExtendedObjectApiCallHelper";
import { GovernanceApiModule, GovernanceHub, GovernanceWebApi, HUB_HTTP_CONNECTION_OPTIONS } from "./services/GovernanceApi";
import { PaginatedFilterTableConfigurationService } from "./services/PaginatedFilterTableConfigurationService";
import { GovernanceStoreModule } from "./store";

@NgModule({
    declarations: [
        AppRootComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        CollapseModule.forRoot(),
        FontAwesomeModule,
        FormlyBootstrapModule,
        FormlyModule.forRoot(),
        RabobankUtilitiesModule,
        RabobankLayoutModule,
        AppRoutingModule,
        AppViewsModule,
        GovernanceStoreModule,
        GovernanceApiModule,
        MsalModule,
    ],
    providers: [
        AppConfigService,
        {
            provide: APP_INITIALIZER,
            deps: [AppConfigService],
            useFactory: (appConfigService: AppConfigService<IEnvironmentConfiguration>) => () => appConfigService.load("assets/app.config.json", "assets/environment-configuration-" + AppConfigService.environmentPlaceholderString + ".json"),
            multi: true
        },

        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpClientLoadingInterceptor,
            multi: true,
        },

        {
            provide: GovernanceWebApi,
            deps: [AppConfigService, HttpClient],
            useFactory: (appConfigService: AppConfigService<IEnvironmentConfiguration>, httpClient: HttpClient) => {
                return new GovernanceWebApi(httpClient, appConfigService.environmentConfiguration.governanceApiUrl);
            },
        },

        {
            provide: HUB_HTTP_CONNECTION_OPTIONS,
            deps: [AppConfigService, MsalService, MsalBroadcastService],
            useFactory: (appConfigService: AppConfigService<IEnvironmentConfiguration>, msalService: MsalService, msalBroadcastService: MsalBroadcastService) => {
                return {
                    accessTokenFactory: () => {
                        return new Promise<string>((resolve, reject) => {
                            return msalBroadcastService.inProgress$
                                .pipe(filter(status => status === InteractionStatus.None))
                                .pipe(mergeMap(() => {
                                    return msalService.acquireTokenSilent({
                                        scopes: [
                                            "api://" + appConfigService.environmentConfiguration.apiClientId + "/access_as_user"
                                        ],
                                        account: msalService.instance.getAllAccounts()[0],
                                    })
                                }))
                                .pipe(map(authenticationResult => {
                                    return authenticationResult.accessToken;
                                }))
                                .pipe(take(1))
                                .subscribe({
                                    next: (accessToken) => {
                                        resolve(accessToken);
                                    },
                                });
                        });
                    },
                } as IHttpConnectionOptions;
            },
        },

        {
            provide: GovernanceHub,
            deps: [AppConfigService, HubConnectionBuilder, HUB_HTTP_CONNECTION_OPTIONS],
            useFactory: (appConfigService: AppConfigService<IEnvironmentConfiguration>, hubConnectionBuilder: HubConnectionBuilder, hubHttpConnectionOptions: IHttpConnectionOptions) => {
                return new GovernanceHub(hubConnectionBuilder, appConfigService.environmentConfiguration.governanceApiUrl, hubHttpConnectionOptions);
            },
        },

        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },

        {
            provide: MSAL_INSTANCE,
            deps: [AppConfigService],
            useFactory: (appConfigService: AppConfigService<IEnvironmentConfiguration>) => {
                return MsalInstanceFactory(appConfigService.environmentConfiguration.appClientId, appConfigService.environmentConfiguration.authority, appConfigService.environmentConfiguration.redirectUrl);
            },
        },

        {
            provide: MSAL_GUARD_CONFIG,
            deps: [AppConfigService],
            useFactory: (appConfigService: AppConfigService<IEnvironmentConfiguration>) => {
                return MsalGuardConfigFactory([
                    "user.read",
                    "openid",
                    "email",
                    "profile",
                    "GroupMember.Read.All",
                    "api://" + appConfigService.environmentConfiguration.apiClientId + "/access_as_user",
                ]);
            },
        },

        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            deps: [AppConfigService],
            useFactory: (appConfigService: AppConfigService<IEnvironmentConfiguration>) => {
                let protectedResourceMap = new Map<string, Array<string>>();
                protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", ["user.read"]);
                protectedResourceMap.set(appConfigService.environmentConfiguration.governanceApiUrl, ["api://" + appConfigService.environmentConfiguration.apiClientId + "/access_as_user"]);

                return MsalInterceptorConfigFactory(protectedResourceMap);
            },
        },

        MsalService,
        MsalGuard,
        MsalBroadcastService,
        ExtendedObjectApiCallHelper,
        PaginatedFilterTableConfigurationService,
        UserInProjectTeamRouteGuard,
    ],
    bootstrap: [AppRootComponent, MsalRedirectComponent],
})
export class AppModule { }