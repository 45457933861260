import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { BaseComponent, IPaginatedFilterTableModalFieldTypeConfiguration } from "rabobank-utilities";
import { ReplaySubject } from "rxjs";
import { map, mergeMap, startWith, take, takeUntil } from "rxjs/operators";
import { IExtendedClaimEntity } from "src/app/models/IExtendedClaimEntity";
import { IExtendedClaimableEnvironment } from "src/app/models/IExtendedClaimableEnvironment";
import { IExtendedCrossVistaTrackTemplate } from "src/app/models/IExtendedCrossVistaTrackTemplate";
import { IStartEnvironmentClaimParameters } from "src/app/services/GovernanceApi";
import { PaginatedFilterTableConfigurationService } from "src/app/services/PaginatedFilterTableConfigurationService";
import { buildVersionValidator } from "src/app/services/Validators";
import { IDataProvider } from "./IDataProvider";

@Component({
    selector: "governance-start-environment-claim-form",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"]
})
export class StartEnvironmentClaimFormComponent extends BaseComponent {
    @Input() dataProvider: IDataProvider;
    @Input() public claimableEnvironmentId: number;
    @Input() public claimableEnvironmentTypeId: number;
    @Input() public requireBuildVersion: boolean;
    @Input() public requireClaimEntity: boolean;

    @Output("formSubmit") public formSubmitEventEmitter: EventEmitter<IStartEnvironmentClaimParameters> = new EventEmitter<IStartEnvironmentClaimParameters>();

    public formGroup: FormGroup = new FormGroup({});
    public model = {} as {} as IStartEnvironmentClaimParameters;
    public formlyFormOptions: FormlyFormOptions = {};
    public formlyFieldConfigList: FormlyFieldConfig[];

    private claimEntityList$: ReplaySubject<IExtendedClaimEntity[]> = new ReplaySubject<IExtendedClaimEntity[]>(1);
    private crossVistaTrackTemplateList$: ReplaySubject<IExtendedCrossVistaTrackTemplate[]> = new ReplaySubject<IExtendedCrossVistaTrackTemplate[]>(1);
    private crossVistaBaselineList$: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

    public constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private paginatedFilterTableConfigurationService: PaginatedFilterTableConfigurationService,
    ) {
        super();

        this.initialize();
    }

    private initialize(): void {
        this.onInit$
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.dataProvider.claimEntityListUpdated$
                        .pipe(startWith([null]))
                        .pipe(takeUntil(this.onDestroy$))
                        .pipe(mergeMap(() => {
                            return this.dataProvider.getClaimEntityList$();
                        }))
                        .subscribe({
                            next: (claimEntityList) => {
                                this.claimEntityList$.next(claimEntityList);
                            },
                        });

                    this.dataProvider.crossVistaTrackTemplateListUpdated$
                        .pipe(startWith([null]))
                        .pipe(takeUntil(this.onDestroy$))
                        .pipe(mergeMap(() => {
                            return this.dataProvider.getCrossVistaTrackTemplateList$();
                        }))
                        .subscribe({
                            next: (crossVistaTrackTemplateList) => {
                                this.crossVistaTrackTemplateList$.next(crossVistaTrackTemplateList);
                            },
                        });

                    //Only try retrieving the crossvista baseline list when a build version is required
                    if (this.requireBuildVersion == true) {
                        this.dataProvider.getCrossVistaBaselineList$(this.claimableEnvironmentTypeId)
                            .pipe(takeUntil(this.onDestroy$))
                            .pipe(take(1))
                            .subscribe({
                                next: (crossVistaBaselineList) => {
                                    this.crossVistaBaselineList$.next(crossVistaBaselineList);
                                },
                            });
                    }
                    else {
                        this.crossVistaBaselineList$.next([]);
                    }

                    this.formlyFieldConfigList = [
                        {
                            key: ["claimableEnvironmentId"],
                            type: "paginated-filter-table-modal",
                            templateOptions: {
                                label: "Claimable environment",
                                type: "number",
                                required: true,
                                readonly: true,
                                paginatedFilterTableModalFieldTypeConfiguration: {
                                    modalTitle: "Select a claimable environment",

                                    modalConfig: {
                                        class: "col-12 col-md-8",
                                        ignoreBackdropClick: true,
                                    },

                                    paginatedFilterTableConfiguration: this.paginatedFilterTableConfigurationService.getClaimableEnvironmentPaginatedFilterTableConfiguration(),

                                    dataProvider: {
                                        getPaginatedResult$: (paginatedRequestParameters) => this.dataProvider.getClaimableEnvironmentPaginatedResult$(paginatedRequestParameters),
                                        listUpdated$: this.dataProvider.claimableEnvironmentListUpdated$,
                                    },

                                    getItemFromValue$: (id) => this.dataProvider.getClaimableEnvironmentFromValue$(id),

                                    itemValueSelector: (item) => item?.id,

                                    itemLabelSelector: (item) => item != null ? (item.extendedClaimableEnvironmentType.name + " - " + item.number) : "No claimable environment selected",
                                } as IPaginatedFilterTableModalFieldTypeConfiguration<IExtendedClaimableEnvironment, number>,
                            },
                        },

                        {
                            key: ["azureDevOpsWorkItemId"],
                            type: "input",
                            templateOptions: {
                                label: "Azure DevOps work item id",
                                placeholder: "Azure DevOps work item id",
                                type: "number",
                                min: 0,
                                required: true,
                            },
                        },

                        {
                            key: ["claimCrossVistaTrackTemplate"],
                            type: "checkbox",
                            templateOptions: {
                                label: "Claim CrossVista track template",
                                required: true,
                            },
                        },

                        {
                            key: ["crossVistaTrackTemplateId"],
                            type: "select",
                            templateOptions: {
                                label: "CrossVista track template",
                                options: this.crossVistaTrackTemplateList$,
                                valueProp: (x: IExtendedCrossVistaTrackTemplate) => x.id,
                                labelProp: (x: IExtendedCrossVistaTrackTemplate) => x.name,
                            },
                            expressionProperties: {
                                "templateOptions.required": "model.claimCrossVistaTrackTemplate == true",
                            },
                            hideExpression: "model.claimCrossVistaTrackTemplate == false",
                        },

                        {
                            key: ["buildVersion"],
                            type: "select",
                            templateOptions: {
                                label: "Build version",
                                options: this.crossVistaBaselineList$.pipe(map(x => x.slice(-10).reverse())), //Get the last 10 items from the list, which should be the most recent build numbers, and then reverse the list so the most recent one is on top
                                valueProp: (x: string) => x,
                                labelProp: (x: string) => x,
                                required: this.requireBuildVersion,
                            },
                            validators: {
                                buildVersion: buildVersionValidator,
                            },
                            hide: this.requireBuildVersion == false,
                        },

                        {
                            key: ["claimEntityIdList"],
                            type: "multicheckbox",
                            templateOptions: {
                                label: "Claim entities",
                                type: "array",
                                options: this.claimEntityList$,
                                valueProp: (x: IExtendedClaimEntity) => x.id,
                                labelProp: (x: IExtendedClaimEntity) => x.name + (x.extendedRequiredClaimEntityList.length == 0 ? "" : " (this will also load: " + x.extendedRequiredClaimEntityList.map(x => x.name).join(", ") + ")"),
                                required: this.requireClaimEntity,
                            },
                            expressionProperties: {
                                "templateOptions.required": "model.claimCrossVistaTrackTemplate == true",
                            },
                            hideExpression: this.requireClaimEntity == false,
                        },
                    ];
                },
            });

        this.afterViewInit$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: () => {
                    this.formGroup.controls.claimableEnvironmentId.setValue(this.claimableEnvironmentId);
                    this.formGroup.controls.claimCrossVistaTrackTemplate.setValue(true);

                    //FormlyFormOptions isn't initialized until AfterViewInit. pdate the initial value of the form so the claimable environment id can't be emptied by resetting the form
                    this.formlyFormOptions.updateInitialValue();

                    this.changeDetectorRef.detectChanges();
                },
            });
    }

    public onFormSubmit(): void {
        if (this.formGroup.valid) {
            this.formSubmitEventEmitter.emit(this.model);
        }
    }
}