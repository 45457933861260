import { Component, EventEmitter, Output } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ApiCallHelper, BaseComponent, CommonModalService } from "rabobank-utilities";
import { map, take, takeUntil } from "rxjs/operators";
import { IExtendedClaimableEnvironment } from "src/app/models/IExtendedClaimableEnvironment";
import { ExtendedObjectApiCallHelper } from "src/app/services/ExtendedObjectApiCallHelper";
import { GovernanceWebApi, IStartEnvironmentClaimParameters } from "src/app/services/GovernanceApi";
import { IDataProvider } from "../../forms/start-environment-claim-form/IDataProvider";

@Component({
    selector: "governance-start-environment-claim-modal",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"],
})
export class StartEnvironmentClaimModalComponent extends BaseComponent {
    @Output("recordSaved") public recordSavedEventEmitter: EventEmitter<number> = new EventEmitter<number>();

    public claimableEnvironment: IExtendedClaimableEnvironment;

    public dataProvider: IDataProvider = {
        getClaimableEnvironmentPaginatedResult$: (paginatedRequestParameters) => {
            return this.extendedObjectApiCallHelper.getClaimableEnvironmentPaginatedResult$(paginatedRequestParameters);
        },

        getClaimableEnvironmentFromValue$: (id) => {
            return this.extendedObjectApiCallHelper.getClaimableEnvironment$(id);
        },

        getClaimEntityList$: () => {
            return this.extendedObjectApiCallHelper.getClaimEntityPaginatedResult$({
                page: 1,
                itemsPerPage: 10000,
                sortConditionList: [
                    {
                        propertyName: "Name",
                        ascending: true,
                    }
                ],
                whereConditionList: [],
            })
                .pipe(map(x => x.itemList));
        },

        getCrossVistaTrackTemplateList$: () => {
            return this.extendedObjectApiCallHelper.getCrossVistaTrackTemplatePaginatedResult$({
                page: 1,
                itemsPerPage: 10000,
                sortConditionList: [
                    {
                        propertyName: "Name",
                        ascending: true,
                    }
                ],
                whereConditionList: [],
            })
                .pipe(map(x => x.itemList));
        },

        getCrossVistaBaselineList$: (claimableEnvironmentTypeId) => {
            return this.apiCallHelper.call$(this.governanceWebApi.crossVistaBaseline.getBaselineList$(claimableEnvironmentTypeId))
                .pipe(this.apiCallHelper.callResultUnwrapPipe());
        },

        claimableEnvironmentListUpdated$: this.extendedObjectApiCallHelper.extendedClaimableEnvironmentListUpdated$,
        claimEntityListUpdated$: this.extendedObjectApiCallHelper.extendedClaimEntityListUpdated$,
        crossVistaTrackTemplateListUpdated$: this.extendedObjectApiCallHelper.extendedCrossVistaTrackTemplateListUpdated$,
    };

    public constructor(
        private extendedObjectApiCallHelper: ExtendedObjectApiCallHelper,
        private governanceWebApi: GovernanceWebApi,
        private apiCallHelper: ApiCallHelper,
        private commonModalService: CommonModalService,
        public bsModalRef: BsModalRef,
    ) {
        super();

        this.initialize();
    }

    private initialize(): void {

    }

    public initializeModal(claimableEnvironment: IExtendedClaimableEnvironment): void {
        this.claimableEnvironment = claimableEnvironment;
    }

    public onFormSubmit(startEnvironmentClaimParameters: IStartEnvironmentClaimParameters): void {
        let loadingModalRef = this.commonModalService.showLoadingModal("Saving record...");

        this.apiCallHelper.call$(this.governanceWebApi.environmentClaim.startEnvironmentClaim$(startEnvironmentClaimParameters))
            .pipe(map(callResult => {
                if (callResult.success == true) {
                    return callResult.result!;
                }

                //If apiExceptionDetails is null, something went wrong with the http call itself
                if (callResult.apiExceptionDetails == null) {
                    this.commonModalService.showHttpErrorModal(callResult.httpErrorResponse!);

                    throw new Error("An error occurred during an HTTP call. Error data: " + JSON.stringify(callResult.httpErrorResponse));
                }

                if (callResult.apiExceptionDetails.errorMessage == "An Azure DevOps work item with the specified id could not be found.") {
                    this.commonModalService.showWarningModal(callResult.apiExceptionDetails.errorMessage, "Azure DevOps error");
                }
                else if (callResult.apiExceptionDetails.errorMessage == "The specified Azure DevOps work item is not a \"Product Backlog Item GRFD\".") {
                    this.commonModalService.showWarningModal("The specified Azure DevOps work item is not a \"Product Backlog Item GRFD\".", "Azure DevOps error");
                }
                else if (callResult.apiExceptionDetails.errorMessage.startsWith("The environment cannot be claimed. Reason:") == true) {
                    this.commonModalService.showWarningModal(callResult.apiExceptionDetails.errorMessage, "Claim error");
                }
                else {
                    this.commonModalService.showApiExceptionDetailsModal(callResult.apiExceptionDetails);
                }

                throw new Error(callResult.apiExceptionDetails!.errorMessage);
            }))
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: (environmentClaimId) => {
                    this.recordSavedEventEmitter.emit(environmentClaimId);

                    this.bsModalRef.hide();
                },
            })
            .add(() => loadingModalRef.hide());
    }
}
