import { Inject, Injectable } from "@angular/core";
import { HubConnectionBuilder, IHttpConnectionOptions } from "@microsoft/signalr";
import { GovernanceDataHub } from "./hubs/GovernanceDataHub";
import { API_URL, HUB_HTTP_CONNECTION_OPTIONS } from "./InjectionTokens";

@Injectable()
export class GovernanceHub {
    public governanceData: GovernanceDataHub;
    
    public constructor(
        hubConnectionBuilder: HubConnectionBuilder,
        @Inject(API_URL) apiUrl: string,
        @Inject(HUB_HTTP_CONNECTION_OPTIONS) hubHttpConnectionOptions: IHttpConnectionOptions,
    ) {
        this.governanceData = new GovernanceDataHub(hubConnectionBuilder, apiUrl, hubHttpConnectionOptions);
    }
}
