import { Component, EventEmitter, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { BaseComponent } from "rabobank-utilities";
import { take, takeUntil } from "rxjs/operators";
import { ISetDisableClaimingForAllClaimableEnvironmentTypesParameters } from "src/app/services/GovernanceApi";

@Component({
    selector: "governance-set-disable-claiming-for-all-claimable-environment-types-form",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"]
})
export class SetDisableClaimingForAllClaimableEnvironmentTypesFormComponent extends BaseComponent {
    @Output("formSubmit") public formSubmitEventEmitter: EventEmitter<ISetDisableClaimingForAllClaimableEnvironmentTypesParameters> = new EventEmitter<ISetDisableClaimingForAllClaimableEnvironmentTypesParameters>();

    public formGroup: FormGroup = new FormGroup({});
    public model: ISetDisableClaimingForAllClaimableEnvironmentTypesParameters = {
        disableClaiming: false,
    } as ISetDisableClaimingForAllClaimableEnvironmentTypesParameters;
    public formlyFormOptions: FormlyFormOptions = {};
    public formlyFieldConfigList: FormlyFieldConfig[];

    public constructor(

    ) {
        super();

        this.initialize();
    }

    private initialize(): void {
        this.onInit$
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.formlyFieldConfigList = [
                        {
                            key: ["disableClaiming"],
                            type: "checkbox",
                            templateOptions: {
                                label: "Disable claiming",
                                required: true,
                            },
                        },

                        {
                            key: ["disableClaimingReason"],
                            type: "input",
                            templateOptions: {
                                label: "Disable claiming reason",
                                placeholder: "Disable claiming reason",
                                type: "text",
                                maxLength: 255,
                                required: true,
                            },
                            hideExpression: "model.disableClaiming == false",
                        },
                    ];
                },
            });
    }

    public onFormSubmit(): void {
        if (this.formGroup.valid) {
            this.formSubmitEventEmitter.emit(this.model);
        }
    }
}