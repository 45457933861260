import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { API_URL } from "./InjectionTokens";
import { AzureDevOpsWorkItemWebApi } from "./webapis/AzureDevOpsWorkItemWebApi";
import { ClaimableEnvironmentTypeWebApi } from "./webapis/ClaimableEnvironmentTypeWebApi";
import { ClaimableEnvironmentWebApi } from "./webapis/ClaimableEnvironmentWebApi";
import { ClaimEntityWebApi } from "./webapis/ClaimEntityWebApi";
import { CrossVistaBaselineWebApi } from "./webapis/CrossVistaBaselineWebApi";
import { CrossVistaTrackTemplateWebApi } from "./webapis/CrossVistaTrackTemplateWebApi";
import { EnvironmentClaimWebApi } from "./webapis/EnvironmentClaimWebApi";
import { ProjectTeamWebApi } from "./webapis/ProjectTeamWebApi";
import { TrackWebApi } from "./webapis/TrackWebApi";
import { UserWebApi } from "./webapis/UserWebApi";

@Injectable()
export class GovernanceWebApi {
    public azureDevOpsWorkItem: AzureDevOpsWorkItemWebApi;
    public claimableEnvironmentType: ClaimableEnvironmentTypeWebApi;
    public claimableEnvironment: ClaimableEnvironmentWebApi;
    public claimEntity: ClaimEntityWebApi;
    public crossVistaBaseline: CrossVistaBaselineWebApi;
    public crossVistaTrackTemplate: CrossVistaTrackTemplateWebApi;
    public environmentClaim: EnvironmentClaimWebApi;
    public projectTeam: ProjectTeamWebApi;
    public track: TrackWebApi;
    public user: UserWebApi;
    
    public constructor(
        httpClient: HttpClient,
        @Inject(API_URL) apiUrl: string,
    ) {
        this.azureDevOpsWorkItem = new AzureDevOpsWorkItemWebApi(httpClient, apiUrl);
        this.claimableEnvironmentType = new ClaimableEnvironmentTypeWebApi(httpClient, apiUrl);
        this.claimableEnvironment = new ClaimableEnvironmentWebApi(httpClient, apiUrl);
        this.claimEntity = new ClaimEntityWebApi(httpClient, apiUrl);
        this.crossVistaBaseline = new CrossVistaBaselineWebApi(httpClient, apiUrl);
        this.crossVistaTrackTemplate = new CrossVistaTrackTemplateWebApi(httpClient, apiUrl);
        this.environmentClaim = new EnvironmentClaimWebApi(httpClient, apiUrl);
        this.projectTeam = new ProjectTeamWebApi(httpClient, apiUrl);
        this.track = new TrackWebApi(httpClient, apiUrl);
        this.user = new UserWebApi(httpClient, apiUrl);
    }
}