import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { BaseComponent, IPaginatedFilterTableConfiguration, IPaginatedResult, PaginatedFilterTableComponent } from "rabobank-utilities";
import { combineLatest, ReplaySubject } from "rxjs";
import { filter, map, mergeMap, startWith, take, takeUntil } from "rxjs/operators";
import { IExtendedEnvironmentClaim } from "src/app/models/IExtendedEnvironmentClaim";
import { PaginatedFilterTableConfigurationService } from "src/app/services/PaginatedFilterTableConfigurationService";
import { IDataProvider } from "./IDataProvider";

@Component({
    selector: "governance-environment-claim-manage",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"],
})
export class EnvironmentClaimManageComponent extends BaseComponent {
    @Input() public dataProvider: IDataProvider;

    @Output("addRecord") public addRecordEventEmitter: EventEmitter<void> = new EventEmitter<void>();
    @Output("editRecord") public editRecordEventEmitter: EventEmitter<number> = new EventEmitter<number>();
    @Output("deleteRecord") public deleteRecordEventEmitter: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild(PaginatedFilterTableComponent) paginatedFilterTableComponent: PaginatedFilterTableComponent<any>;
    @ViewChild("tableHeaderCellAdd", { static: true }) tableHeaderCellAddTemplateRef: TemplateRef<any>;
    @ViewChild("tableBodyCellEdit", { static: true }) tableBodyCellEditTemplateRef: TemplateRef<any>;
    @ViewChild("tableBodyCellDelete", { static: true }) tableBodyCellDeleteTemplateRef: TemplateRef<any>;

    public paginatedFilterTableConfiguration: IPaginatedFilterTableConfiguration<IExtendedEnvironmentClaim>;

    public paginatedResult$: ReplaySubject<IPaginatedResult<IExtendedEnvironmentClaim>> = new ReplaySubject<IPaginatedResult<IExtendedEnvironmentClaim>>(1);

    public constructor(
        private paginatedFilterTableConfigurationService: PaginatedFilterTableConfigurationService,
    ) {
        super();

        this.initialize();
    }

    private initialize(): void {
        this.onInit$
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    let paginatedFilterTableConfiguration = this.paginatedFilterTableConfigurationService.getEnvironmentClaimPaginatedFilterTableConfiguration();

                    paginatedFilterTableConfiguration.tableConfiguration.tableColumnList.push({
                        headerCellValue: "",
                        bodyCellTemplateRef: this.tableBodyCellEditTemplateRef,
                        headerCellCssClassList: ["align-middle"],
                    });

                    paginatedFilterTableConfiguration.tableConfiguration.tableColumnList.push({
                        headerCellTemplateRef: this.tableHeaderCellAddTemplateRef,
                        bodyCellTemplateRef: this.tableBodyCellDeleteTemplateRef,
                        headerCellCssClassList: ["align-middle"],
                    });

                    this.paginatedFilterTableConfiguration = paginatedFilterTableConfiguration;

                    combineLatest([
                        this.paginatedFilterTableConfiguration.paginatedRequestHelper.paginatedRequestParameters$.pipe(filter(x => x != null), map(x => x!)),
                        this.dataProvider.environmentClaimListUpdated$.pipe(startWith([null])),
                    ])
                        .pipe(mergeMap(([
                            paginatedRequestParameters,
                        ]) => {
                            return this.dataProvider.getEnvironmentClaimPaginatedResult$(paginatedRequestParameters);
                        }))
                        .pipe(takeUntil(this.onDestroy$))
                        .subscribe({
                            next: (paginatedResult) => {
                                this.paginatedResult$.next(paginatedResult);
                            },
                        });
                },
            });

            this.afterViewInit$
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.paginatedFilterTableComponent.applyFilters();
                },
            });
    }

    public onAddRecord(): void {
        this.addRecordEventEmitter.emit();
    }

    public onEditRecord(id: number): void {
        this.editRecordEventEmitter.emit(id);
    }

    public onDeleteRecord(id: number): void {
        this.deleteRecordEventEmitter.emit(id);
    }
}
