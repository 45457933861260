import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FormlyFieldConfig, FormlyFormOptions } from "@ngx-formly/core";
import { BaseComponent, IPaginatedFilterTableModalFieldTypeConfiguration } from "rabobank-utilities";
import { take, takeUntil } from "rxjs/operators";
import { IExtendedClaimableEnvironmentType } from "src/app/models/IExtendedClaimableEnvironmentType";
import { IExtendedProjectTeam } from "src/app/models/IExtendedProjectTeam";
import { IClaimableEnvironment } from "src/app/services/GovernanceApi";
import { PaginatedFilterTableConfigurationService } from "src/app/services/PaginatedFilterTableConfigurationService";
import { IDataProvider } from "./IDataProvider";

@Component({
    selector: "governance-claimable-environment-form",
    templateUrl: "./component.html",
    styleUrls: ["./component.scss"]
})
export class ClaimableEnvironmentFormComponent extends BaseComponent {
    @Input() dataProvider: IDataProvider;

    @Output("formSubmit") public formSubmitEventEmitter: EventEmitter<IClaimableEnvironment> = new EventEmitter<IClaimableEnvironment>();

    public formGroup: FormGroup = new FormGroup({});
    public model: IClaimableEnvironment = {
        allowEntireTrackToClaim: false,
        forAdministratorOnly: false,
    } as IClaimableEnvironment;
    public formlyFormOptions: FormlyFormOptions = {};
    public formlyFieldConfigList: FormlyFieldConfig[];

    public constructor(
        private paginatedFilterTableConfigurationService: PaginatedFilterTableConfigurationService,
    ) {
        super();

        this.initialize();
    }

    private initialize(): void {
        this.onInit$
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this.formlyFieldConfigList = [
                        {
                            key: ["id"],
                            type: "input",
                            templateOptions: {
                                label: "Id",
                                placeholder: "Id",
                                type: "number",
                                required: false,
                            },
                            hide: true,
                        },

                        {
                            key: ["claimableEnvironmentTypeId"],
                            type: "paginated-filter-table-modal",
                            templateOptions: {
                                label: "Claimable environment type",
                                type: "number",
                                required: true,
                                paginatedFilterTableModalFieldTypeConfiguration: {
                                    modalTitle: "Select a claimable environment type",

                                    modalConfig: {
                                        class: "col-12 col-md-8",
                                        ignoreBackdropClick: true,
                                    },

                                    paginatedFilterTableConfiguration: this.paginatedFilterTableConfigurationService.getClaimableEnvironmentTypePaginatedFilterTableConfiguration(),

                                    dataProvider: {
                                        getPaginatedResult$: (paginatedRequestParameters) => this.dataProvider.getClaimableEnvironmentTypePaginatedResult$(paginatedRequestParameters),
                                        listUpdated$: this.dataProvider.claimableEnvironmentTypeListUpdated$,
                                    },

                                    getItemFromValue$: (id) => this.dataProvider.getClaimableEnvironmentTypeFromValue$(id),

                                    itemValueSelector: (item) => item?.id,

                                    itemLabelSelector: (item) => item != null ? item.name : "No claimable environment type selected",
                                } as IPaginatedFilterTableModalFieldTypeConfiguration<IExtendedClaimableEnvironmentType, number>,
                            },
                        },

                        {
                            key: ["projectTeamId"],
                            type: "paginated-filter-table-modal",
                            templateOptions: {
                                label: "Project team",
                                type: "number",
                                required: false,
                                paginatedFilterTableModalFieldTypeConfiguration: {
                                    modalTitle: "Select a project team",

                                    modalConfig: {
                                        class: "col-12 col-md-8",
                                        ignoreBackdropClick: true,
                                    },

                                    paginatedFilterTableConfiguration: this.paginatedFilterTableConfigurationService.getProjectTeamPaginatedFilterTableConfiguration(),

                                    dataProvider: {
                                        getPaginatedResult$: (paginatedRequestParameters) => this.dataProvider.getProjectTeamPaginatedResult$(paginatedRequestParameters),
                                        listUpdated$: this.dataProvider.projectTeamListUpdated$,
                                    },

                                    getItemFromValue$: (id) => this.dataProvider.getProjectTeamFromValue$(id),

                                    itemValueSelector: (item) => item?.id,

                                    itemLabelSelector: (item) => item?.name ?? "No project team selected",
                                } as IPaginatedFilterTableModalFieldTypeConfiguration<IExtendedProjectTeam, number>,
                            },
                        },

                        {
                            key: ["allowEntireTrackToClaim"],
                            type: "checkbox",
                            templateOptions: {
                                label: "Allow entire track to claim",
                                required: true,
                            },
                        },

                        {
                            key: ["number"],
                            type: "input",
                            templateOptions: {
                                label: "Number",
                                placeholder: "Number",
                                type: "number",
                                min: 0,
                                required: true,
                            },
                        },

                        {
                            key: ["label"],
                            type: "input",
                            templateOptions: {
                                label: "Label",
                                placeholder: "Label",
                                type: "text",
                                maxLength: 10,
                                required: true,
                            },
                        },

                        {
                            key: ["buildVersionSchemaName"],
                            type: "input",
                            templateOptions: {
                                label: "Build version schema name",
                                placeholder: "Build version schema name",
                                type: "text",
                                maxLength: 64,
                                required: true,
                            },
                        },

                        {
                            key: ["nexGenSchemaName"],
                            type: "input",
                            templateOptions: {
                                label: "NexGen schema name",
                                placeholder: "NexGen schema name",
                                type: "text",
                                maxLength: 64,
                                required: true,
                            },
                        },

                        {
                            key: ["targetPowerCenterRepository"],
                            type: "input",
                            templateOptions: {
                                label: "Target PowerCenter repository",
                                placeholder: "Target PowerCenter repository",
                                type: "text",
                                maxLength: 64,
                                required: true,
                            },
                        },

                        {
                            key: ["targetHanaSchemaName"],
                            type: "input",
                            templateOptions: {
                                label: "Target HANA schema name",
                                placeholder: "Target HANA schema name",
                                type: "text",
                                maxLength: 64,
                                required: true,
                            },
                        },

                        {
                            key: ["hanaSchemaName"],
                            type: "input",
                            templateOptions: {
                                label: "HANA schema name",
                                placeholder: "HANA schema name",
                                type: "text",
                                maxLength: 64,
                                required: true,
                            },
                        },

                        {
                            key: ["forAdministratorOnly"],
                            type: "checkbox",
                            templateOptions: {
                                label: "For administrator only",
                                required: true,
                            },
                        },

                        {
                            key: ["bitbucketBranchPrefix"],
                            type: "input",
                            templateOptions: {
                                label: "Bitbucket branch prefix",
                                placeholder: "Bitbucket branch prefix",
                                type: "text",
                                maxLength: 255,
                                required: true,
                            },
                        },
                    ];
                },
            });
    }

    public loadRecord(id: number): void {
        this.dataProvider.getClaimableEnvironment$(id)
            .pipe(takeUntil(this.onDestroy$))
            .pipe(take(1))
            .subscribe({
                next: (claimableEnvironment) => {
                    this.model = claimableEnvironment;

                    this.formlyFormOptions.updateInitialValue();
                },
            });
    }

    public onFormSubmit(): void {
        if (this.formGroup.valid) {
            this.formSubmitEventEmitter.emit(this.model);
        }
    }
}